import React, { useContext }from "react";
import './pages.css'
import { DatePicker, Empty, Modal, Tabs } from "antd";
import { useState } from "react";
import DataGrid from "../components/common/DataGrid";
import { useEffect } from "react";
import userService from "../service/user.service";
import Loading from "../components/Loading";
import Footer from "../components/Footer";
import { Icon } from "@iconify/react";
import { ConfigContext } from '../ConfigContext';
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { mapIsMultilevel } from "../data/typeMapping";

const { TabPane } = Tabs;

function Downline() {
  const {t} = useTranslation();
  const downlineColumns = [
    {
      name: `${t("S_No")}`,
    },
    {
      name: `${t("Downline")}`,
    },
    {
      name: `${t("Registered_DateTime")}`,
    },
  ];
  const commissionColumns = [
    {
      name: `${t("Date")}`,
    },
    {
      name: `${t("Downline")}`,
    },
    {
      name: `${t("Type")}`,
    },
    {
      name: `${t("Win/Loss")}`,
    },
    {
      name: `${t("Status")}`,
    }
  ];
  const laporanColumns = [{ name: "Tarikh&Masa" }, { name: "Commission" }];
  const [columns, setColumns] = useState(downlineColumns);
  const [downline, setDownline] = useState([]);
  const [selected, setSelected] = useState("downline");
  const [fromDate, setFromDate] = useState("downline");
  const [toDate, setToDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [itemsNo, setItemsNo] = useState({ start: 0, end: 20 });
  // const [totalCommissions, setTotalCommissions] = useState(0)
  const [isMoreData, setIsMoreData] = useState(true);
  const [gameError, setGameError] = useState(null);
  const [globalSettings, setGlobalSettings] = useState(null);

  const configData = useContext(ConfigContext)


  const categories = [
    {
      id: 1,
      name: "downline",
      description: "Downline",
      // icon: require("../images/casino.png"),
    },
    {
      id: 2,
      name: "commission",
      description: "Commission",
      // icon: require("../images/sportbook.png"),
    },
    {
      id: 3,
      name: "laporan",
      description: "Laporan",
      // icon: require("../images/slot.png"),
    },
  ];

  const getUpdatedCommission = async () => {
    setLoading(true);
    const data = await userService.getCommissionsSingleMultilevel(fromDate, toDate,itemsNo.start,
      itemsNo.end);
    if (data && data.data && data.data.length) {
      if (data.data.length < 20) {
        setIsMoreData(false);
      }
      let newdata = data.data.map((d) => {
        if(d.reward_amount > 0) {
          return {
            date: d.from_date,
            downline: d.referred_player,
            type: mapIsMultilevel(d.is_multilevel),
            commission: d.reward_amount,
            state: d.state,
          };
        }else {
          return null; // Return a placeholder value for cases where the condition is not met
        }
      })
      .filter((item) => item !== null); // Remove the null values to filter out items that didn't meet the condition
      const totalSingleMultilevelAmount = await getTotalSingleMultilevelAmount(data.data)
      newdata = [...totalSingleMultilevelAmount, ...newdata];
      setColumns(commissionColumns);
      setDownline(newdata);
    } else {
      setDownline([]);
    }
    setLoading(false);
  };
  const getTotalSingleMultilevelAmount = async(data) => {
    const singleLevelApproved = data.filter(item => item.is_multilevel === 0 && item.state === "approved");
    const multiLevelApproved = data.filter(item => item.is_multilevel === 1 && item.state === "approved");
    const singleLevelTotalApproved = singleLevelApproved.reduce((acc, item) => acc + item.reward_amount, 0);
    const multiLevelTotalApproved = multiLevelApproved.reduce((acc, item) => acc + item.reward_amount, 0);
    const singleLevelPending = data.filter(item => item.is_multilevel === 0 && item.state === "pending");
    const multiLevelPending = data.filter(item => item.is_multilevel === 1 && item.state === "pending");
    const singleLevelTotalPending = singleLevelPending.reduce((acc, item) => acc + item.reward_amount, 0);
    const multiLevelTotalPending = multiLevelPending.reduce((acc, item) => acc + item.reward_amount, 0);

    return [      
      { label: `${t("Total_single_level_referral")} (${t("Approved")})`, empty: "", empty1: "", TotalAmount: singleLevelTotalApproved, state: "approved" },
      { label: `${t("Total_single_level_referral")} (${t("Pending")})`, empty: "", empty1: "", TotalAmount: singleLevelTotalPending, state: "pending" },
      { label: `${t("Total_multi_level_referral")} (${t("Approved")})`, empty: "", empty1: "", TotalAmount: multiLevelTotalApproved, state: "approved" },
      { label: `${t("Total_multi_level_referral")} (${t("Pending")})`, empty: "", empty1: "", TotalAmount: multiLevelTotalPending, state: "pending" },
    ]
    
  }

  const getCommissionPercent = async (comms) => {
    let availableTypes = []
    comms.forEach(commission => {
      if (!availableTypes.includes(commission.ttype) || availableTypes.length<2) {
        availableTypes.push(commission.ttype)
      }
    })
    let totalLossRef, totalLossReb, reward_amountRef, reward_amountReb;
          totalLossRef = comms.filter(com => com.ttype == "RF").reduce(
            (accumulator, currentValue) =>
              accumulator + parseInt(currentValue.calculated_amount),
            0
          );
          totalLossReb = comms.filter(com => com.ttype == "RB").reduce(
            (accumulator, currentValue) =>
              accumulator + parseInt(currentValue.calculated_amount),
            0
          );
          reward_amountRef = comms.filter(com => com.ttype == "RF").reduce(
            (accumulator, currentValue) =>
              accumulator + parseInt(currentValue.reward_amount),
            0
          );
          reward_amountReb = comms.filter(com => com.ttype == "RB").reduce(
            (accumulator, currentValue) =>
              accumulator + parseInt(currentValue.reward_amount),
            0
          );
          const lossReb = {
            label: t("Total_Loss") + "(Rebate)",
            loss: totalLossReb ? totalLossReb : 0,
          };
          const lossRef = {
            label: t("Total_Loss") + "(Referral)",
            loss: totalLossRef ? totalLossRef : 0,
          };
          const commissionReb = {
            label: t("Commission") + " Rebate" + "(" + (globalSettings?globalSettings.rebate_commission_percentage_sl_rebate:0) + "%)",
            reward: reward_amountReb ? reward_amountReb : 0,
          };
          const commissionRef = {
            label: t("Commission") + " Referral" + "(" + (globalSettings?globalSettings.rebate_commission_percentage_sl_referral:0) + "%)",
            reward: reward_amountRef ? reward_amountRef : 0,
          };
          const lossAndComm = []
          if (availableTypes.includes("RF")) {
            lossAndComm.push(lossRef,commissionRef)
          }
          if (availableTypes.includes("RB")) {
            lossAndComm.push(lossReb,commissionReb)
          }
          return lossAndComm;
  }
  const getDownline = async () => {
    try {
      let data, newdata;
      if (selected === "downline") {
        data = await userService.getDownline(itemsNo.start, itemsNo.end);
        if (data && data.data && data.data.length) {
          newdata = data.data.map((d, i) => {
            const date = d.date_joined.split(/T|\./);
            return {
              s_no: i + 1,
              downline: d.username,
              date_joined: date[0] + " " + date[1],
            };
          });
          setColumns(downlineColumns);
          setDownline(newdata);
        } else {
          setDownline([]);
        }
      }
      if (selected === "commission") {
        data = await userService.getCommissionsSingleMultilevel(
          null,
          null,
          itemsNo.start,
          itemsNo.end
        );
        // setTotalCommissions(data.headers.get('X-Total-Count'))
        if (data && data.data && data.data.length) {
          if (data.data.length < 20) {
            setIsMoreData(false);
          }
          newdata = data.data.map((d) => {
            if(d.reward_amount >= 0) {
              return {
                date: d.from_date,
                downline: d.referred_player,
                type: mapIsMultilevel(d.is_multilevel),
                commission: d.reward_amount,
                state: d.state,
              };
            }else {
              return null; // Return a placeholder value for cases where the condition is not met
            }
          })
          .filter((item) => item !== null); // Remove the null values to filter out items that didn't meet the condition
          
          const totalSingleMultilevelAmount = await getTotalSingleMultilevelAmount(data.data)
          newdata = [...totalSingleMultilevelAmount, ...newdata];
          setColumns(commissionColumns);
          setDownline(newdata);
        } else {
          setDownline([]);
        }
      }
      if (selected === "laporan") {
        data = await userService.getLaporan(itemsNo.start, itemsNo.end);
        if (data && data.data && data.data.length) {
          newdata = data.data.map((d) => {
            return { date: d.to_date, commission: d.reward_amount };
          });
          setColumns(laporanColumns);
          setDownline(newdata);
        } else {
          setDownline([]);
        }
      }
      setLoading(false);
      setGameError(null);
    } catch (err) {
      setLoading(false);
      setGameError({ msg: err.message });
    }
  };

  const getMoreItems = async () => {
    let data, newdata;
    if (!isMoreData) return;
    if (selected === "downline") {
      data = await userService.getDownline(itemsNo.end + 1, itemsNo.end + 20);
      setItemsNo({ start: itemsNo.end + 1, end: itemsNo.end + 20 });
      if (data && data.data && data.data.length) {
        if (data.data.length < 20) {
          setIsMoreData(false);
        }
        newdata = data.data.map((d, i) => {
          const date = d.date_joined.split(/T|\./);
          return {
            s_no: downline.length + i + 1,
            downline: d.username,
            date_joined: date[0] + " " + date[1],
          };
        });
        setColumns(downlineColumns);
        setDownline((prevDownline) => {
          return [...prevDownline, ...newdata];
        });
      }
    }
    if (selected === "commission") {
      data = await userService.getCommissionsSingleMultilevel(
        null,
        null,
        itemsNo.end + 1,
        itemsNo.end + 20
      );
      setItemsNo({ start: itemsNo.end + 1, end: itemsNo.end + 20 });

        if (data && data.data && data.data.length) {
          if (data.data.length < 20) {
            setIsMoreData(false);
          }
          newdata = data.data.map((d) => {
            if(d.reward_amount > 0) {
              return {
                date: d.from_date,
                downline: d.referred_player,
                type: mapIsMultilevel(d.is_multilevel),
                commission: d.reward_amount,
                state: d.state,
              };
            }else {
              return null; // Return a placeholder value for cases where the condition is not met
            }
          })
        .filter((item) => item !== null); // Remove the null values to filter out items that didn't meet the condition

        const totalSingleMultilevelAmount = await getTotalSingleMultilevelAmount(data.data)
        newdata = [...totalSingleMultilevelAmount, ...newdata];
        setColumns(commissionColumns);
        setDownline([...downline, ...newdata]);
      }
    }
    if (selected === "laporan") {
      data = await userService.getLaporan(itemsNo.end + 1, itemsNo.end + 20);
      setItemsNo({ start: itemsNo.end + 1, end: itemsNo.end + 20 });
      if (data && data.data && data.data.length) {
        if (data.data.length < 20) {
          setIsMoreData(false);
        }
        newdata = data.data.map((d) => {
          return { date: d.to_date, commission: d.reward_amount };
        });
        setColumns(laporanColumns);
        setDownline([...downline, ...newdata]);
      }
    }
  };

  const getGlobalSettings = async () => {
    const settings = await userService.getGlobalSettings();
    if (settings && settings.data && settings.data.length) {
      setGlobalSettings(settings.data[0])
    }
  }

  useEffect(() => {
    setLoading(true);
    getDownline();
    getGlobalSettings();
  }, [selected]);

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    setTimeout(() => {
      Array.from(document.querySelectorAll('.MuiTab-root')).forEach((ele) =>{
        ele.style.background = "transparent"; 
        ele.style.color = "#ccc";

      }) 
      
      Array.from(document.querySelectorAll('.Mui-selected')).forEach((ele) =>{
        ele.style.background = configData?configData.secondary_color:"";
        ele.style.color = configData?configData.text_color :"";
      }) 
    }, 100);
  };

  return (
    <>
      <div
        className="content t3 notices"
        id="tab-group-1"
        style={{ paddingBottom: "70px" }}
      >
        {/* <Tabs
          onChange={e => {
            setSelected(e)
            setIsMoreData(true)
            setItemsNo({ start: 0, end: 20 })
            setTimeout(() => {
              Array.from(document.querySelectorAll('.ant-tabs-tab .ant-tabs-tab-btn')).forEach((ele) =>{
                ele.style.background = "transparent"; 
                ele.style.color = "#ccc";
        
              }) 
              
              Array.from(document.querySelectorAll('.ant-tabs-tab-active .ant-tabs-tab-btn')).forEach((ele) =>{
                ele.style.background = configData?configData.secondary_color:"";
                ele.style.color = configData?configData.text_color :"";
              }) 
            }, 100);
          }}
        >
          {categories.map((category) => (
            <TabPane

              tab={
                <div className="category-game" style={{ height: "20px" }}>
                  <span>{category.description}</span>
                </div>
              }
              key={category.name}
            ></TabPane>
          ))}
        </Tabs> */}
        <TabContext value={selected}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: "1rem", marginBottom: "8px" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t("Downline")} value="downline" />
              <Tab label={t("Commission")} value="commission" />
              <Tab label={t("Laporan")} value="laporan" />
            </TabList>
          </Box>
          {/* <TabPanel value="1">Downline</TabPanel>
          <TabPanel value="2">Commission</TabPanel>
          <TabPanel value="3">Laporan</TabPanel> */}
        </TabContext>
        {selected === "commission" ? (
          <div className="d-flex mb-2" style={{ gap: "10px" }}>
            <DatePicker
              onChange={(e) => {
                if (e && e["$d"]) {
                  const dd = new Date(e["$d"]);
                  const updatedDate = `${dd.getUTCFullYear()}-${
                    (dd.getUTCMonth() + 1) < 10 ? "0"+(dd.getUTCMonth() + 1) : (dd.getUTCMonth() + 1)
                  }-${dd.getUTCDate()}`;
                  setFromDate(updatedDate);
                }
              }}
              style={{ width: "40%" }}
              size="small"
            />
            <DatePicker
              onChange={(e) => {
                if (e && e["$d"]) {
                  const dd = new Date(e["$d"]);
                  const updatedDate = `${dd.getUTCFullYear()}-${
                    (dd.getUTCMonth() + 1) < 10 ? "0"+(dd.getUTCMonth() + 1) : (dd.getUTCMonth() + 1)
                  }-${dd.getUTCDate()}`;
                  setToDate(updatedDate);
                }
              }}
              style={{ width: "40%" }}
              size="small"
            />
            <button
              disabled={!fromDate || !toDate}
              style={{
                background: configData?configData.text_color_secondary : "",
                width: "15%",
                borderRadius: "6px",
                fontWeight: "bold",
              }}
              onClick={() => {
                if (fromDate && toDate) {
                  getUpdatedCommission();
                }
              }}
            >
              {t("Get")}
            </button>
          </div>
        ) : null}
        {downline && downline.length ? (
          <DataGrid
            columns={columns}
            data={downline}
            getMoreItems={getMoreItems}
            isMoreData={isMoreData}
          />
        ) : (
          <>{!loading && <Empty description={t("No_items_found") + "!"} />}</>
        )}
        {/* <Pagination total={3} /> */}
        {loading && <Loading />}
      </div>
      <div className="t1">
        <Footer />
      </div>
      <Modal
        title={"Game Error"}
        footer={[]}
        open={gameError}
        onCancel={() => setGameError(false)}
        style={{ textAlign: "center" }}
      >
        <div>
          <div className="flex flex-row justify-center">
            <Icon icon="codicon:error" color="#f27474" width={70} />
            {/* <img
                src={selectedGame.image_mobile}
                alt={selectedGame.name}
                style={{ width: "150px", borderRadius: "1rem" }}
              /> */}
          </div>
          <p className="text-md font-medium mt-3">
            {gameError && gameError.msg ? gameError.msg : null}
          </p>
          <button
            onClick={() => setGameError(false)}
            className={`btn btn-sm bg-blue-dark rounded-xs text-uppercase
            font-700 w-100 btn-l mt-3 h-12`}
          >
            {t("OK")}
          </button>
        </div>
      </Modal>
    </>
  );
}

export default Downline;
