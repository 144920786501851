import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import backgroundImg from '../../src/images/ECI88_Background.png';
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../../ConfigContext";
import Footer from "../../Footer";
import Loading from "../../Loading";
import UserService from "../../../service/user.service";
import LayoutCard from "../common/LayoutCard";
import "../layout.css"
import InfoIcon from '@mui/icons-material/Info';
import TransitionsModal from "../../common/TransitionsModal";
import TransitionsModalPromotion from "../../common/TransitionsModalPromotion";
import TransitionsModalQRCode from "../../common/TransitionsModalQRCode";
import PromotionDescription, { formatPromotionMessage } from "../../PromotionDescription"
import { Upload, message } from "antd";
// import { toast } from 'react-toastify';
import { Icon } from "@iconify/react";
import { DGPayPaymentOptions } from "../../../pages/Deposit";
import useToast from "../../../utils/useToast";

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
};
const init_validation = {
    amount: "",
    merchant_bank_account: "",
    non_field_errors: "",
    proof: ""
};
const Deposit2 = () => {
    // const [typelist, setTypelist] = useState([]);
    // const [viewMercantBank, setViewMerchantBank] = useState("word");
    const [ewalletsList, setEWalletsList] = useState([]);
    const [merchantBanks, setMerchantBanks] = useState([]);
    const [banks, setBanks] = useState([]);
    const [loading, setAPILoading] = useState(false);
    const [selectedBank, setSelectedBank] = useState(null);
    const [validations, setValidations] = useState(init_validation);
    const [promotionList, setPromotionList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalDGPayDepositOpen, setModalDGPayDepositOpen] = useState(false);
    const [modalDGPayQRISOpen, setModalDGPayQRISOpen] = useState(false);
    const [qrisData, setQRISData] = useState({amount: 0, url: ""});
    const [selectedTnC, setSelectedTnC] = useState({
        title: "",
        description: "",
      });
    // const [depSuccessful, setDepSuccessful] = useState('');
    const toast = useToast();

    const initialValues = {
        ttype: "IB",
        amount: "",
        proof: undefined,
        non_field_errors: "",
        merchant_bank_account: "",
    };

    const [values, setValues] = useState(initialValues);
    const [isLoading, setLoading] = useState(false);
    // const [ewalletsList, setEWalletsList] = useState([]);
    const [imageUrl, setImageUrl] = useState();
    const [mobile, setMobile] = useState(0);
    const [messageApi, contextHolder] = message.useMessage();
    const configData = useContext(ConfigContext)
    const [depSuccessful, setDepSuccessful] = useState('');

    const navigate = useNavigate();
    const { t } = useTranslation();
    const success = () => {
        messageApi.open({
          type: 'success',
          content: 'Bank Account Number Copied',
        });
      };
    const onChangeRadio = (e) => {
        // setRadioValue(e.target.value);
        if (!e.target.checked) {
          setValues((preValues) => {
            const newVals = JSON.parse(JSON.stringify(preValues))
            delete newVals["promotion"]
            return { ...newVals };
          });
        } else {
          setValues((values) => {
            return { ...values, promotion: parseInt(e.target.value) };
          });
        }
    };

    const getPromotions = async () => {
        setAPILoading(true);
        const promotionListing = await UserService.getPromotionsDepositList();
    
        promotionListing.data.sort((a, b) => a.id - b.id);
        setPromotionList(promotionListing.data.filter(v=>v.other.applicable));
        setAPILoading(false);
    };

    const getMerchantBanklist = async () => {
        setAPILoading(true);
        const WalletInfo = await UserService.getBalance();
        const { rank_id, mobile } = WalletInfo.data;
        setMobile(mobile)
        const getMerchantlist = rank_id ? await UserService.getMerchantBanksWithRank(rank_id) : await UserService.getMerchantBanks();
        setMerchantBanks(getMerchantlist.data);
        setAPILoading(false);
    };
    const getBankList = async () => {
        setAPILoading(true);
        const getBanks = await UserService.getBanks();
        setBanks(getBanks.data);
        setAPILoading(false);
    }
    
    const getBackgroundClass = (item) => {
        const depositConfig = configData ? configData : {}; // Ensure configData is not null or undefined
    
        // Define the mapping between item.name and background class
        const mapping = {
          "surepay_TNG": depositConfig.deposit_tng || "",
          "surepay_DQR": depositConfig.deposit_doitnow || "",
          "surepay_DuitNow": depositConfig.deposit_doitnow || "",
          "dgpay_TNG": depositConfig.deposit_dgpay_tng || "",
          "dgpay_DQR": depositConfig.deposit_dgpay_doitnow || "",
          "dgpay_DuitNow": depositConfig.deposit_dgpay_doitnow || "",
        };
        return mapping[item.name] || "";
      };
    
    const getMerchantEWalletslist = async () => {
        setLoading(true);
        const ewallets = await UserService.getMerchantEWallets(values.pg);
        setEWalletsList(ewallets.data);
        setLoading(false);
    };

    useEffect(() => {
        // getCouponTypes();
        getBankList();
        getMerchantBanklist()
        getMerchantEWalletslist();
        getPromotions();
    }, [values])

    const handleNumChange = (e) => {
        const RE = /^\d*\.?\d{0,18}$/;
        if (RE.test(e.target.value) || e.target.value === '') {
            setValues(values => {
                return { ...values, [e.target.name]: e.target.value };
            });
        }
    }
    const handleFileChange = (e) => {
        e.target.value &&
        getBase64(e.target.files[0], (url) => {
            const proofData = {};
            proofData.title = e.target.files[0].name;
            proofData.base64 = url;
            setValues({ ...values, proof: proofData });
            setLoading(false);
            setImageUrl(url);
        });
    };
    const handleDeposit = async e => {
        e.preventDefault();
        setAPILoading(true);
        let response;
        try {
            response = await UserService.createDeposit(values);
            // setDepSuccessful(response.data.id)
            navigate("/history")
            // Modal.success({
            //   content: <p>Deposit is successful. <br />
            //     Transaction ID: {response.data.id}</p>,
            // });
            setImageUrl();
            setValues(initialValues);
            setValidations(init_validation);
            // navigate("/history")
        } catch (err) {
            // console.log("err => ", err);
            let non_msg;
            let amount_msg;
            let bank_msg;
            let proof_msg;

            if (err.response.data.amount) {
                amount_msg = err.response.data.amount;
            }
            if (err.response.data.merchant_bank_account) {
                bank_msg = err.response.data.merchant_bank_account;
            }
            if (err.response.data.non_field_errors) {
                non_msg = err.response.data.non_field_errors;
            }
            if (err.response.data.proof) {
                proof_msg = err.response.data.proof;
            }
            setValidations({
                ...validations,
                amount: amount_msg,
                merchant_bank_account: bank_msg,
                non_field_errors: non_msg,
                proof: proof_msg
            });
        }
        setAPILoading(false);
    }

    const handleOnlineDeposit = async e => {
        e.preventDefault();

        setLoading(true);
        let response;
        try {
            response = await UserService.createDeposit(values);

            let url=""
            if (values.pg == "dgpay") {
                // DGPay Checking for ID country
                const url = response.data.action;
                if (url) {
                    if(values.provider == 30){
                        setQRISData({
                            "amount": values.amount,
                            "url": url
                        })
                        setModalDGPayQRISOpen(true)
                    }else {
                        window.open(url);
                        setModalDGPayDepositOpen(true)
                    }
                } else if (values.provider == 11) {
                    setModalDGPayDepositOpen(true)
                } else {
                toast.error(t("dgpay_payment_failed"))
                }
                setLoading(false);
                setValues(initialValues);

                return;
            } else if (values.pg == "sun2pay") {
                url = JSON.parse(response.data.action.data)[0].payment_url
            } else {
                url = response.data.action.url
            }
            const bankdata = response.data.action.data;

            let form_data = new FormData();

            Object.entries(bankdata).forEach(([key, value]) => {
                form_data.append(`${key}`, value);
            })

            try {

                var form = document.createElement("form");

                form.method = "POST";
                form.action = url;

                let element = [{}];
                Object.entries(bankdata).forEach(([key, value], index) => {
                    element[index] = document.createElement("input");
                    element[index].name = key;
                    element[index].value = value;
                    form.appendChild(element[index]);
                })

                document.body.appendChild(form);
                form.style = "display: none";

                form.submit();

                document.body.removeChild(form);

            } catch (error) {
                console.log("error ==> ", error);
            }

            // TransitionsModal.success({
            //     content: <p>Deposit is successful. <br />
            //         Transaction ID: {response?.data?.id}</p>,
            // });

            // setValues(initialValues);
        } catch (err) {
            console.log("err => ", err, values)
            let amount_msg;
            let non_msg;
            let proof_msg;
            
            if (err.response.data.amount) {
                amount_msg = err.response.data.amount;
            }
            if (err.response.data.non_field_errors) {
                non_msg = err.response.data.non_field_errors;
            }
            if (err.response.data.proof) {
                proof_msg = err.response.data.proof;
            }
            // if (err?.response?.data?.cc) {
            //     cc_message = err.response.data.cc;
            // }
            setValidations({
                ...validations, 
                amount: amount_msg, 
                non_field_errors: non_msg, 
                proof: proof_msg
            });
        }

        setLoading(false);
    }

    return (
        <>
        {toast.ToastContainer}
        {contextHolder}
        <div className="t1">
            <div className="page">
                <div className="page-content">
                    <div className="deposit2-container layout2 px-2 py-3" style={{color: configData?configData.text_color:"#fff", backgroundColor: configData?configData.primary_color:"#151515"}}>
                    <div class="tab-menu-container" style={{border: configData?`1px solid ${configData.secondary_color}`:"", color: configData?configData.text_color:"#fff"}}>
                        <a style={{color: configData?configData.text_color:"#999", borderRight: configData?configData.border_color_secondary:"#000", backgroundColor: configData?configData.secondary_color:""}} href="#" data-active="true" onClick={(e)=>{
                            e.preventDefault();
                            navigate("/deposit");
                        }}>
                            {t("Deposit")}
                        </a>
                        <a style={{color: configData?configData.text_color:"#999", borderRight: configData?configData.border_color_secondary:"#000"}} href="#" data-active="false" onClick={(e)=>{
                            e.preventDefault();
                            navigate("/withdraw");
                        }}>
                            {t("Withdraw")}
                        </a>
                    </div>
                    <LayoutCard>
                        <label style={{color: configData?configData.text_color:"#fff"}} for="PaymentMethod" className="payment-method-label">{t("Payment_Method")}</label>
                        <div id="payment_method_selection" className="payment-method-selection">
                            <input type="radio" name="PaymentType" id="payment_method_MANUAL_TRANSFER" value="MANUAL_TRANSFER" />
                            <label style={{color: configData?configData.text_color:"#fff", backgroundColor: configData?(values && (values.ttype == "IB")?configData.secondary_color:configData.tertiary_color):""}} for="payment_method_MANUAL_TRANSFER" onClick={() => {
                                setValues(prevVals => {
                                    delete prevVals["pg"]
                                    prevVals = { ...prevVals, ttype: "IB" }
                                    return prevVals
                                });
                            }}>
                                <img loading="lazy" src={configData ? configData.deposit_manual_deposit : ""} />
                                <span>MANUAL</span>
                            </label>
                            {
                                configData && configData.available_paymentgateway_providers?
                                configData.available_paymentgateway_providers.split(",").map(provider=>(
                                    <>
                                        <input type="radio" name="PaymentType" id={provider} value={provider} />
                                        <label style={{color: configData?configData.text_color:"#fff", backgroundColor: configData?(values && (values.pg == provider)?configData.secondary_color:configData.tertiary_color):""}} for={provider} onClick={() => {
                                            setValues(prevVals => {
                                                delete prevVals["merchant_bank_account"]
                                                delete prevVals["proof"]
                                                prevVals = { ...prevVals, ttype: `${configData && configData.country === 'ID' ? "EW" : "PG"}`, pg: provider }
                                                return prevVals
                                            });
                                        }}>
                                            <img loading="lazy" src={configData ? configData.deposit_easypay : ""} />
                                            <span>
                                                {
                                                    provider === 'dgpay' ?
                                                        configData && configData.country === 'ID' ?
                                                        `e-wallet`
                                                        :
                                                        provider.toUpperCase()
                                                    :
                                                    provider.toUpperCase()
                                                }
                                            </span>
                                        </label>
                                    </>
                                )):null
                            }
                            {
                                configData && configData.available_ewallets?
                                configData.available_ewallets.split(",").map(provider=>(
                                    <>
                                        <input type="radio" name="PaymentType" id="payment_method_EWALLET" value="EWALLET" />
                                        <label style={{color: configData?configData.text_color:"#fff", backgroundColor: configData?(values && (values.pg == provider)?configData.secondary_color:configData.tertiary_color):""}} for="payment_method_EWALLET" onClick={() => {
                                            setValues(prevVals => {
                                                delete prevVals["merchant_bank_account"]
                                                delete prevVals["proof"]
                                                prevVals = { ...prevVals, ttype: "EW", pg: provider }
                                                return prevVals
                                            });
                                        }}>
                                            <img loading="lazy" src={configData ? configData[`deposit_${provider}`] : ""} />
                                            <span>
                                                {
                                                    provider === 'dgpay-ewallet' ?
                                                        configData && configData.country === 'ID' ?
                                                        `e-wallet`
                                                        :
                                                        provider.toUpperCase()
                                                    :
                                                    provider.toUpperCase()
                                                }
                                            </span>
                                        </label>
                                    </>
                                )):null
                            }
                            {/* {
                                ENABLE_EASYPAY &&
                                <>
                                    <input type="radio" name="PaymentType" id="payment_method_EASYPAY" value="EASYPAY" />
                                    <label style={{color: configData?configData.text_color:"#fff", backgroundColor: configData?(values && (values.pg == "easypay")?configData.secondary_color:configData.tertiary_color):""}} for="payment_method_EASYPAY" onClick={() => {
                                        setValues(prevVals => {
                                            delete prevVals["merchant_bank_account"]
                                            delete prevVals["proof"]
                                            prevVals = { ...prevVals, ttype: "PG", pg: 'easypay' }
                                            return prevVals
                                        });
                                    }}>
                                        <img loading="lazy" src={configData ? configData.deposit_easypay : ""} />
                                        <span>EASYPAY</span>
                                    </label>
                                </>
                            }
                            {
                                ENABLE_SUREPAY &&
                                <>
                                    <input type="radio" name="PaymentType" id="payment_method_SUREPAY" value="SUREPAY" />
                                    <label style={{color: configData?configData.text_color:"#fff", backgroundColor: configData?(values && (values.pg == "surepay")?configData.secondary_color:configData.tertiary_color):""}} for="payment_method_SUREPAY" onClick={() => {
                                        setValues(prevVals => {
                                            delete prevVals["merchant_bank_account"]
                                            delete prevVals["proof"]
                                            prevVals = { ...prevVals, ttype: "PG", pg: 'surepay' }
                                            return prevVals
                                        });
                                    }}>
                                        <img loading="lazy" src={configData ? configData.deposit_surepay : ""} />
                                        <span>SUREPAY</span>
                                    </label>
                                </>
                            }
                            {
                                ENABLE_DGPAY &&
                                <>
                                    <input type="radio" name="PaymentType" id="payment_method_DGPAY" value="DGPAY" />
                                    <label style={{
                                        color: configData?configData.text_color:"#fff", 
                                        backgroundColor: configData?(values && (values.pg == "dgpay")?configData.secondary_color:configData.tertiary_color):""
                                    }} 
                                    for="payment_method_DGPAY" onClick={() => {
                                        setValues(prevVals => {
                                            delete prevVals["merchant_bank_account"]
                                            delete prevVals["proof"]
                                            prevVals = { ...prevVals, ttype: "EW", pg: 'dgpay' }
                                            return prevVals
                                        });
                                    }}>
                                        <img loading="lazy" src={configData ? configData.deposit_surepay : ""} />
                                        <span>EWALLET</span>
                                    </label>
                                </>
                            } */}
                            {/* {
                                ENABLE_SUREPAY_EWALLET &&
                                <>
                                    <input type="radio" name="PaymentType" id="payment_method_EWALLET" value="EWALLET" />
                                    <label style={{color: configData?configData.text_color:"#fff", backgroundColor: configData?(values && (values.pg == "EW")?configData.secondary_color:configData.tertiary_color):""}} for="payment_method_EWALLET" onClick={() => {
                                        setValues(prevVals => {
                                            delete prevVals["merchant_bank_account"]
                                            delete prevVals["proof"]
                                            prevVals = { ...prevVals, ttype: "EW", pg: 'EW' }
                                            return prevVals
                                        });
                                    }}>
                                        <img loading="lazy" src={configData ? configData.deposit_surepay : ""} />
                                        <span>EWALLET</span>
                                    </label>
                                </>
                            } */}
                        </div>
                    </LayoutCard>
                    {(values.pg !="surepay-ewallet") && (values.pg !="dgpay-ewallet") &&
                    <LayoutCard>
                        <div className="form-group deposit-form-group">
                            <label style={{color: configData?configData.text_color:"#fff"}} for="Amount">{t("Amount")} {values.pg == "dgpay" && '(Minimal Deposit Ewallet RP 20000)'}</label>
                            <div className="amount-container">
                                <input style={{
                                    backgroundColor: configData?configData.inputs_bg:"", 
                                    color: configData?configData.text_color:"",
                                    border: "1px solid #e4ae60"
                                }} 
                                autocomplete="off" className="form-control deposit_amount_input" data-val="true" data-val-required="The Amount field is required." id="Amount" name="amount" type="text" value={values.amount} onChange={handleNumChange} />
                                {/* <span className="standard-required-message">Please input number only for deposit amount.</span> */}
                                {
                                    values &&
                                    <div className="real-deposit-amount" id="real_deposit_amount" data-title={t("Total_Amount_Need")}>{values.amount}</div>
                                }
                            </div>
                            {
                                validations &&
                                <span class="standard-required-message">{validations.amount}</span>
                            }
                        </div>

                        { values.pg == "dgpay" && 
                        <div className="form-group deposit-form-group">
                            <label style={{color: configData?configData.text_color:"#fff"}} for="Provider">{t("Provider")}</label>
                            
                            <select
                                style={{
                                    backgroundColor: configData?configData.inputs_bg:"",
                                    color: configData?configData.text_color :"",
                                    border: "1px solid #e4ae60"
                                }}
                                className="form-control rounded-xs"
                                value={values.provider} placeholder="Select Provider" onChange={(e)=>setValues({...values,provider:e.target.value})} id="Provider" name="Provider">
                                <option value="">{t("Select_A_Value")}</option>
                                {
                                    DGPayPaymentOptions(configData && configData.country).map((payment, idx) => (
                                        <option value={payment.id}>
                                            {payment.name}
                                        </option>
                                    ))
                                }
                            </select>
                            <p className="text-md d-flex mt-2 align-items-center" style={{color: configData?configData.text_color_secondary:"", gap: "5px", lineHeight: 1.5}}>
                                <InfoIcon /> {`${t('Confirm_Mobile')} ${mobile}`}
                            </p>
                        </div>
                        }
                    </LayoutCard>
                    }
                    {
                        values.ttype == "IB" ?
                            <LayoutCard>
                                <div className="deposit-form-group">
                                    <div className="form-group">
                                        <div className="to-account-label-container">
                                            <label style={{color: configData?configData.text_color:"#fff"}} for="ToAccount">{t("To_Number")}</label>
                                            {/* <span id="view_all_available_banks" onClick={(e) => {
                                                e.preventDefault();
                                                viewMercantBank == "icon" ?
                                                setViewMerchantBank("word")
                                                :
                                                setViewMerchantBank("icon")
                                            }}>{viewMercantBank == "icon" ? "View Less" : "View All"}</span> */}
                                        </div>
                                        {/* {viewMercantBank == "icon" ? */}
                                        <div className="banks-container">
                                            <div className="banks-wrap">
                                            {merchantBanks.map((bank, index) => (
                                                <div
                                                className={`col-4 bank-item ${
                                                    selectedBank && selectedBank.id === bank.id
                                                    ? "selected-bank"
                                                    : ""
                                                    }`}
                                                key={index}
                                                onClick={() => {
                                                    setValues({ ...values, merchant_bank_account: bank.id });
                                                    setSelectedBank(bank);
                                                }}
                                                >
                                                <img
                                                    src={
                                                    banks.length > 0 &&
                                                        banks.find((bk) => bk.id === bank.bank).icon
                                                        ? banks.find((bk) => bk.id === bank.bank).icon
                                                        : "/images/game01.png"
                                                    }
                                                    width={50}
                                                    alt=""
                                                />
                                                {/* <img src={bank.icon ? bank.icon : '/images/game01.png'} width={50} alt='' /> */}
                                                <div style={{ fontSize: "12px" }}>{bank.name}</div>
                                                </div>
                                            ))}
                                            </div>
                                        </div>
                                        {/* :
                                        <select style={{backgroundColor: configData?configData.inputs_bg:"", color: configData?configData.text_color:""}} onChange={(e) => {
                                            // console.log("values", JSON.parse(e.target.value))
                                            const newBank = JSON.parse(e.target.value)
                                            setValues({ ...values, merchant_bank_account: newBank.id });
                                            setSelectedBank(newBank);
                                        }} name="merchant_bank_account" id="deposit_bank_select" className="form-control" >
                                            <option disabled selected>
                                                {t("Select_Bank")}
                                            </option>
                                            {
                                                merchantBanks.map((bank, index) => (
                                                    <option value={JSON.stringify(bank)}>
                                                        {bank.name}|{bank.number}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                        } */}
                                        {/* <span className="standard-required-message">Please select company bank to deposit</span> */}
                                    </div>
                                    {
                                        validations &&
                                        <span class="standard-required-message">{validations.merchant_bank_account}</span>
                                    }
                                    {
                                        selectedBank &&
                                        <div className="form-group">
                                            <div data-section="input" data-bank-type="pulsa" className="bank-info" id="bank_info" data-high-priority="false">
                                                <div data-bank-info="header">
                                                    <h1 id="bank_info_account_name"></h1>
                                                    <div id="bank_info_logo">
                                                        <img src={banks.length > 0 &&
                                                            banks.find((bk) => bk.id === selectedBank.bank).icon
                                                            ? banks.find((bk) => bk.id === selectedBank.bank).icon
                                                            : "/images/game01.png"} style={{ display: "block" }} /></div>
                                                    <h3 id="bank_info_name" className="bank-name">{selectedBank.name}</h3>
                                                </div>
                                                {
                                                    selectedBank &&
                                                    <div data-bank-info="details" style={{color: configData?configData.text_color:""}}>
                                                        <h2 id="bank_info_account_no">{selectedBank.number}</h2>
                                                    </div>
                                                }
                                                <hr />
                                                <div data-bank-info="actions">
                                                    <button className="copy-bank-account-button" id="copy_bank_account_button" type="button" style={{color: configData?configData.text_color_secondary:""}}
                                                     onClick={() => {
                                                        navigator.clipboard.writeText(selectedBank.number)
                                                        success(selectedBank.number)
                                                    }}>
                                                        {t("Copy")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </LayoutCard>
                            : null
                    }
                    {
                        values.ttype == "IB" ?
                        
                            <LayoutCard>
                                <label style={{color: configData?configData.text_color:"#fff"}} for="TransactionReceipt">{t("Transaction_Receipt")}</label>
                                <div data-section="input" className="reciept-file" style={{backgroundColor: configData?configData.inputs_bg:"", color: configData?configData.text_color:""}}>
                                    <label for="TransactionReceipt" class="btn"
                                    style={{
                                        display: "inline-block",
                                        cursor: "pointer",
                                        color: "#fff", /* Set your preferred text color */
                                        border: "1px solid white",
                                        borderRadius: "5px",
                                        transition: "background-color 0.3s ease",
                                    }}>
                                        <Icon icon="material-symbols:upload" width="20" height="20"/>
                                        {t("Select_Image")}
                                    </label>
                                    <input style={{color: configData?configData.text_color:"" ,visibility:"hidden", contentVisibility: "hidden"}}  className="form-control" id="TransactionReceipt" name="TransactionReceipt" type="file" 
                                    onChange={handleFileChange}/>
                                        {imageUrl && (
                                            <img src={imageUrl} alt="Selected File" style={{height: '50px'}}/>
                                        )}
                                </div>
                                {validations &&
                                <span class="standard-required-message">{validations.proof}</span>}
                            </LayoutCard>
                            : null
                    }
                    {(values.pg !="surepay-ewallet") && (values.pg !="dgpay-ewallet") &&
                        configData&&!configData.enable_ctc && 
                        <LayoutCard>     
                            <h4 className="mt-4" style={{color: configData?configData.text_color :"" }}>{t("Bonus")} {t("Selection")} <span style={{color: configData?configData.text_color_secondary:""}}>({t("optional")}):</span></h4>
                            <div className="px-2" style={{border: "1px solid #e4ae60"}}>
                            {promotionList &&
                                promotionList.length > 0 &&
                                promotionList
                                .filter((item) => item.active)
                                .map((item) => {
                                    return (
                                    <div className="bonus-radio_container d-flex align-items-center my-2" key={item.id}>
                                        <div className="bonus-selector d-flex position-relative" style={{ overflow: "hidden" }}>
                                        <input className="bonus-radio" name="bonus-radio" type="checkbox" id="" value={item.id}
                                        onChange={e=>{
                                            // if(item.id === 0){
                                            //   return
                                            // }
                                            if (e.target.checked) {
                                            setModalOpen(true);
                                            setSelectedTnC(item)
                                            } else {
                                            setSelectedTnC({
                                                title: "",
                                                description: "",
                                            })
                                            }
                                            onChangeRadio(e)
                                        }}
                                        checked={item.id == values.promotion}
                                        onClick={() => {
                                            // setModalOpen(true);
                                            if (item.id !== 0) {
                                            setValues((values) => {
                                                return { ...values, promotion: item.id };
                                            })
                                            } else {
                                            delete values.promotion;
                                            }

                                        }}
                                        />
                                        <div className="bonus-select p-2 d-flex justify-content-center align-items-center">
                                            <span className="bonus-check">&#10004;</span>
                                        </div>
                                        </div>
                                        <label className="ml-1 text-uppercase font-bold" style={{ color: configData?configData.text_color :"" }} htmlFor="">{item.title}</label>
                                    </div>
                                    );
                                })}
                            </div>
                            <p className="text-md d-flex mt-2 align-items-center" style={{color: configData?configData.text_color_secondary:"", gap: "5px", lineHeight: 1.5}}><InfoIcon /> {promotionList && promotionList.length ? t("PromotionMessage") : t("PromotionMessage2")}</p>
                        </LayoutCard> 
                    }

                    {
                        validations &&
                        <div>
                            {
                            validations.non_field_errors && validations.non_field_errors.map((item, index) => (
                            <div key={index}>
                                <span class="standard-required-message">{formatPromotionMessage(item)}</span>
                            </div>
                            ))
                            }
                        </div>
                    }
                    {(values.pg =="surepay-ewallet") || (values.pg =="dgpay-ewallet") ?
                        ewalletsList && ewalletsList.map((item, index) => {
                            return (
                                <div className={`deposit_payment`} key={index} onClick={() => {
                                    if ((values.pg =="dgpay-ewallet")) {
                                        navigate(`/dgpay-ewallet/${item.id}`)
                                    } else {
                                        navigate(`/ewallet/${item.id}`)
                                    }
                                }}>
                                    <div className="tng-background" style={{backgroundImage: `url(${getBackgroundClass(item)})`}}>
                                        <div className="d-flex flex-col justify-center" style={{ width: "calc(100% - 45px)" }}></div>
                                    </div>
                                </div>

                            )
                        })
                    :
                    <button className="secondary-button text-uppercase" style={{backgroundColor: configData? configData.secondary_color:"", color: configData?configData.text_color:""}} 
                    onClick={values && 
                    (values.pg == "easypay" || values.pg == "surepay" || values.pg == "dgpay" || values.pg == "sun2pay") ? (
                    handleOnlineDeposit 
                    ) : (values.ttype === "IB") ? (
                    handleDeposit
                    ) : null
                    }>
                        {t("Deposit")}
                    </button>
                    }
                </div>
                </div>
                <Footer />
            </div>
            {isLoading && <Loading />}
        </div>
        <TransitionsModalPromotion
            title={selectedTnC.title}
            className="antd-promotion-modal"
            footer={[]}
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            style={{ textAlign: "center" }}
        >
            <PromotionDescription
                field={selectedTnC.description}
                data={""}
            />
        </TransitionsModalPromotion>
        <TransitionsModal
            title={selectedTnC.title}
            centered
            open={modalDGPayDepositOpen}
            footer={[]}
            onClose={() => setModalDGPayDepositOpen(false)}
        >
            <div style={{ textAlign: 'center', padding: '20px' }}>
                <p>{t("dgpay_payment_check")}</p>
                <button
                    className={`btn btn-sm bg-green-dark rounded-xs text-uppercase font-700 btn-l mt-3 h-12`}
                    onClick={() => navigate("/history")}
                    style={{ margin: 'auto' }}>
                    {t("OK")}
                </button>
            </div>
        </TransitionsModal>
        <TransitionsModal
                title={''}
                centered
                open={depSuccessful ? true : false}
                footer={[]}
                // onClose={() => setDepSuccessful(false)}
            >
                <div className="d-flex align-items-center">
                <Icon icon="ep:success-filled" color="#52c41a" fontSize={22} />
                <p style={{fontSize:"14px", paddingLeft:"10px"}}>{t("Deposit_is_successful")}. <br />
                {t("Transaction_Id")}: {depSuccessful}</p>
                </div>

                <button style={{marginLeft:"auto", background: "#1677ff", fontWeight:"100", fontSize:"15px"}}
                    onClick={() => {
                    setDepSuccessful('')
                    navigate("/history")

                    }}
                    className={`btn btn-sm d-flex py-2 ml-auto rounded-xs text-uppercase
                                mt-3`}>{t("OK")}
                </button>
            </TransitionsModal>
            <TransitionsModalQRCode
                title={''}
                centered
                open={modalDGPayQRISOpen}
                footer={[]}
                style={{ height: "600px", padding: "5px" }}
                // onClose={() => setDepSuccessful(false)}
            >
                <div className="d-flex align-items-center m-2" 
                style={{ backgroundColor: configData?configData.secondary_color:"", padding: "10px", borderRadius: "5px" }}>
                    <p style={{ fontSize: "14px", margin: 0, color: configData?configData.text_color:"" }}>
                        {t('QRCode_Notice')}
                    </p>
                </div>
                <div className="d-flex align-items-center m-2" 
                style={{ backgroundColor: configData?configData.secondary_color:"", padding: "10px", borderRadius: "5px" }}>
                    <p style={{ fontSize: "14px", margin: 0 }}>
                        <span style={{ fontWeight: "bold", color: configData?configData.text_color:"" }}>
                            {t('Bank')}: QRIS
                        </span>
                        <br />
                        <span style={{ fontWeight: "bold", color: configData?configData.text_color:"" }}>
                            {t('Amount')}: {Number(qrisData.amount).toFixed(2)}
                        </span>
                    </p>
                </div>
                <iframe
                allowtransparency="true" 
                frameborder="0" 
                scrolling="no" 
                width="100%" 
                height="60%"
                src={qrisData.url}
                // src="https://api-prod.mitrapayment.com/image-qris/MDAwMjAxMDEwMjEyMjY2NzAwMTZDT00uTk9CVUJBTksuV1dXMDExODkzNjAwNTAzMDAwMDA4OTgwMjAyMTQ5MjQ1MDI1MDc3MTUyMjAzMDNVS0U1MTQ0MDAxNElELkNPLlFSSVMuV1dXMDIxNUlEMjAyMzI5MzU3NjExMjAzMDNVS0U1MjA0NTQ5OTUzMDMzNjA1NDA1MjAwMDA1ODAySUQ1OTA4bmF3YXNlbmE2MDE1SkFLQVJUQSBTRUxBVEFONjEwNTc4MTEzNjI5MTAxMTQxMjI4MDIzNjcxODMyNzA1MjV2bGFtYy1vYmh0eS14eGFyaC1kc3hxbHN5MDYyNXZsYW1jLW9iaHR5LXh4YXJoLWRzeHFsc3kwNzAzQTAxMDgwNFBPU1A2MzA0QjBDQQ=="
                />
                <button style={{marginLeft:"auto", background: "#1677ff", fontWeight:"100", fontSize:"15px"}}
                    onClick={() => {
                    setModalDGPayQRISOpen(false)
                    setQRISData({amount: 0, url: ""})
                    navigate("/history")
                    }}
                    className={`btn btn-sm d-flex py-2 m-auto rounded-xs text-uppercase
                                mt-3`}>{t("Close")}
                </button>
            </TransitionsModalQRCode>
        </>
    );
};

export default Deposit2;
