import React, { createContext, useEffect, useState } from "react";
import { configurableData } from "./utils/configurable";
import userService from "./service/user.service";
import { useTranslation } from "react-i18next";

export const ConfigContext = createContext()

const API_URL = process.env.REACT_APP_APIURL
export const ConfigContextProvider = (props) => {
  const [configData, setConfigData] = useState(null)
  const {t} = useTranslation();
  const getConfigData = async () => {
    try {
      const response = await userService.getConfigs();
      const skinData = response && response.data && response.data.length ? response.data[0] : null;
      let newRes = {
        "id": 1,
        available_paymentgateway_providers: skinData && skinData.available_paymentgateway_providers ? skinData.available_paymentgateway_providers : configurableData.available_paymentgateway_providers,
        available_ewallets: skinData && skinData.available_ewallets ? skinData.available_ewallets : configurableData.available_ewallets,
        live_table_style: skinData && skinData.live_table_style ? skinData.live_table_style : configurableData.live_table_style,
        top_x_amount: skinData && skinData.top_x_amount ? skinData.top_x_amount : configurableData.top_x_amount,
        top_y_amount: skinData && skinData.top_y_amount ? skinData.top_y_amount : configurableData.top_y_amount,
        top_z_amount: skinData && skinData.top_z_amount ? skinData.top_z_amount : configurableData.top_z_amount,
        jackpot: skinData && skinData.jackpot ? skinData.jackpot : configurableData.jackpot,
        change_game_id: skinData && skinData.change_game_id ? skinData.change_game_id : configurableData.change_game_id,
        default_lang: skinData && skinData.default_lang ? skinData.default_lang : configurableData.default_lang,
        available_languages: skinData && skinData.available_languages ? skinData.available_languages : configurableData.available_languages,
        footer_content_enabled: skinData && skinData.footer_content_enabled ? skinData.footer_content_enabled : configurableData.footer_content_enabled,
        footer_content: skinData && skinData.footer_content ? skinData.footer_content : configurableData.footer_content,
        login_style: skinData && skinData.login_style ? skinData.login_style : configurableData.login_style,
        signup_version: skinData && skinData.signup_version ? skinData.signup_version : configurableData.signup_version,
        bank_accounts_style: skinData && skinData.bank_accounts_style ? skinData.bank_accounts_style : configurableData.bank_accounts_style,
        profile_style: skinData && skinData.profile_style ? skinData.profile_style : configurableData.profile_style,
        change_password_style: skinData && skinData.change_password_style ? skinData.change_password_style : configurableData.change_password_style,
        sidebar_menu_item_bg: skinData && skinData.sidebar_menu_item_bg ? skinData.sidebar_menu_item_bg : configurableData.sidebar_menu_item_bg,
        layout_card_bg: skinData && skinData.layout_card_bg ? skinData.layout_card_bg : configurableData.layout_card_bg,
        tertiary_color: skinData && skinData.tertiary_color ? skinData.tertiary_color : configurableData.tertiary_color,
        inputs_bg: skinData && skinData.inputs_bg ? skinData.inputs_bg : configurableData.inputs_bg,
        sidebar_toggle_position: skinData && skinData.sidebar_toggle_position ? skinData.sidebar_toggle_position : configurableData.sidebar_toggle_position,
        sidebar_position: skinData && skinData.sidebar_position ? skinData.sidebar_position : configurableData.sidebar_position,
        promo_style: skinData && skinData.promo_style ? skinData.promo_style : configurableData.promo_style,
        deposit_style: skinData && skinData.deposit_style ? skinData.deposit_style : configurableData.deposit_style,
        withdraw_style: skinData && skinData.withdraw_style ? skinData.withdraw_style : configurableData.withdraw_style,
        history_tabs_style: skinData && skinData.history_tabs_style ? skinData.history_tabs_style : configurableData.history_tabs_style,
        language_toggle_position: skinData && skinData.language_toggle_position ? skinData.language_toggle_position : configurableData.language_toggle_position,
        general_buttons_rows: skinData && skinData.general_buttons_rows ? skinData.general_buttons_rows : configurableData.general_buttons_rows,
        member_auth_style: skinData && skinData.member_auth_style ? skinData.member_auth_style : configurableData.member_auth_style,
        primary_color: skinData && skinData.primary_color ? skinData.primary_color : configurableData.primary_color,
        secondary_color: skinData && skinData.secondary_color ? skinData.secondary_color : configurableData.secondary_color,
        text_color : skinData && skinData.text_color  ? skinData.text_color  : configurableData.text_color ,
        live_deposit_bg : skinData && skinData.live_deposit_bg  ? skinData.live_deposit_bg  : configurableData.live_deposit_bg ,
        live_withdraw_bg : skinData && skinData.live_withdraw_bg  ? skinData.live_withdraw_bg  : configurableData.live_withdraw_bg ,
        live_transaction_bg : skinData && skinData.live_transaction_bg  ? skinData.live_transaction_bg  : configurableData.live_transaction_bg ,
        deposit_withdraw_title_bg : skinData && skinData.deposit_withdraw_title_bg  ? skinData.deposit_withdraw_title_bg  : configurableData.deposit_withdraw_title_bg ,
        country: skinData && skinData.country ? skinData.country : configurableData.country,
        default_product_load: skinData && skinData.default_product_load ? skinData.default_product_load : configurableData.default_product_load,
        border_color: skinData && skinData.border_color ? skinData.border_color : configurableData.border_color,
        border_color_secondary : skinData && skinData.border_color_secondary  ? skinData.border_color_secondary  : configurableData.border_color_secondary ,
        border_shadow_primary_color: skinData && skinData.border_shadow_primary_color ? skinData.border_shadow_primary_color : configurableData.border_shadow_primary_color,
        border_shadow_secondary_color : skinData && skinData.border_shadow_secondary_color  ? skinData.border_shadow_secondary_color  : configurableData.border_shadow_secondary_color ,
        text_color_secondary : skinData && skinData.text_color_secondary  ? skinData.text_color_secondary  : configurableData.text_color_secondary ,
        progress_bar : skinData && skinData.progress_bar  ? skinData.progress_bar  : configurableData.progress_bar ,
        max_rtp : skinData && skinData.max_rtp  ? skinData.max_rtp  : configurableData.max_rtp ,
        min_rtp : skinData && skinData.min_rtp  ? skinData.min_rtp  : configurableData.min_rtp ,
        rtp_1_color : skinData && skinData.rtp_1_color  ? skinData.rtp_1_color  : configurableData.rtp_1_color ,
        rtp_2_color : skinData && skinData.rtp_2_color  ? skinData.rtp_2_color  : configurableData.rtp_2_color ,
        rtp_3_color : skinData && skinData.rtp_3_color  ? skinData.rtp_3_color  : configurableData.rtp_3_color ,
        rtp_4_color : skinData && skinData.rtp_4_color  ? skinData.rtp_4_color  : configurableData.rtp_4_color ,
        rtp_5_color : skinData && skinData.rtp_5_color  ? skinData.rtp_5_color  : configurableData.rtp_5_color ,
        rtp_6_color : skinData && skinData.rtp_6_color  ? skinData.rtp_6_color  : configurableData.rtp_6_color ,
        rtp_7_color : skinData && skinData.rtp_7_color  ? skinData.rtp_7_color  : configurableData.rtp_7_color ,
        rtp_8_color : skinData && skinData.rtp_8_color  ? skinData.rtp_8_color  : configurableData.rtp_8_color ,
        rtp_9_color : skinData && skinData.rtp_9_color  ? skinData.rtp_9_color  : configurableData.rtp_9_color ,
        rtp_10_color : skinData && skinData.rtp_10_color  ? skinData.rtp_10_color  : configurableData.rtp_10_color ,
        footer_bg_active_color : skinData && skinData.footer_bg_active_color  ? skinData.footer_bg_active_color  : configurableData.footer_bg_active_color ,
        withdraw_chip_bg : skinData && skinData.withdraw_chip_bg  ? skinData.withdraw_chip_bg  : configurableData.withdraw_chip_bg ,
        category_tab_1_type : skinData && skinData.category_tab_1_type  ? skinData.category_tab_1_type  : configurableData.category_tab_1_type ,
        category_tab_2_type : skinData && skinData.category_tab_2_type  ? skinData.category_tab_2_type  : configurableData.category_tab_2_type ,
        category_tab_3_type : skinData && skinData.category_tab_3_type  ? skinData.category_tab_3_type  : configurableData.category_tab_3_type ,
        category_tab_4_type : skinData && skinData.category_tab_4_type  ? skinData.category_tab_4_type  : configurableData.category_tab_4_type ,
        category_tab_5_type : skinData && skinData.category_tab_5_type  ?  skinData.category_tab_5_type  : configurableData.category_tab_5_type,
        category_tab_6_type : skinData && skinData.category_tab_6_type  ?  skinData.category_tab_6_type  : configurableData.category_tab_6_type,
        category_tab_7_type : skinData && skinData.category_tab_7_type  ?  skinData.category_tab_7_type  : configurableData.category_tab_7_type,
        category_tab_8_type : skinData && skinData.category_tab_8_type  ?  skinData.category_tab_8_type  : configurableData.category_tab_8_type,
        category_tab_9_type : skinData && skinData.category_tab_9_type  ?  skinData.category_tab_9_type  : configurableData.category_tab_9_type,
        category_tab_10_type : skinData && skinData.category_tab_10_type  ?  skinData.category_tab_10_type  : configurableData.category_tab_10_type,
        category_tab_1_active : skinData && skinData.category_tab_1_active  ? skinData.category_tab_1_active  : configurableData.category_tab_1_active ,
        category_tab_2_active : skinData && skinData.category_tab_2_active  ? skinData.category_tab_2_active  : configurableData.category_tab_2_active ,
        category_tab_3_active : skinData && skinData.category_tab_3_active  ? skinData.category_tab_3_active  : configurableData.category_tab_3_active ,
        category_tab_4_active : skinData && skinData.category_tab_4_active  ? skinData.category_tab_4_active  : configurableData.category_tab_4_active ,
        category_tab_5_active : skinData && skinData.category_tab_5_active  ?  skinData.category_tab_5_active  : configurableData.category_tab_5_active,
        category_tab_6_active : skinData && skinData.category_tab_6_active  ?  skinData.category_tab_6_active  : configurableData.category_tab_6_active,
        category_tab_7_active : skinData && skinData.category_tab_7_active  ?  skinData.category_tab_7_active  : configurableData.category_tab_7_active,
        category_tab_8_active : skinData && skinData.category_tab_8_active  ?  skinData.category_tab_8_active  : configurableData.category_tab_8_active,
        category_tab_9_active : skinData && skinData.category_tab_9_active  ?  skinData.category_tab_9_active  : configurableData.category_tab_9_active,
        category_tab_10_active : skinData && skinData.category_tab_10_active  ?  skinData.category_tab_10_active  : configurableData.category_tab_10_active,

        category_image_height : skinData && skinData.category_image_height  ?  skinData.category_image_height  : configurableData.category_image_height,
        category_image_width : skinData && skinData.category_image_width  ?  skinData.category_image_width  : configurableData.category_image_width,
        category_tab_height : skinData && skinData.category_tab_height  ?  skinData.category_tab_height  : configurableData.category_tab_height,
        category_tab_width : skinData && skinData.category_tab_width  ?  skinData.category_tab_width  : configurableData.category_tab_width,
        category_text : skinData && skinData.category_text  ?  true  : false,

        general_buttons : skinData && skinData.general_buttons  ? true  : false ,
        live_table : skinData && skinData.live_table  ? true  : false ,
        sub_line : skinData && skinData.sub_line  ? true  : false ,
        sub_line_fix : skinData && skinData.sub_line_fix  ? true  : false ,
        daily_checkin : skinData && skinData.daily_checkin  ? true  : false ,
        checkin_appearance : skinData && skinData.checkin_appearance  ? skinData.checkin_appearance  : configurableData.checkin_appearance ,
        promo_rewards : skinData && skinData.promo_rewards  ? true  : false ,

        // Appended URL
        category_tab_1_icon : skinData && skinData.category_tab_1_icon  ?  API_URL + "/media/" + skinData.category_tab_1_icon  : configurableData.category_tab_1_icon,
        category_tab_2_icon : skinData && skinData.category_tab_2_icon  ?  API_URL + "/media/" + skinData.category_tab_2_icon  : configurableData.category_tab_2_icon,
        category_tab_3_icon : skinData && skinData.category_tab_3_icon  ?  API_URL + "/media/" + skinData.category_tab_3_icon  : configurableData.category_tab_3_icon,
        category_tab_4_icon : skinData && skinData.category_tab_4_icon  ?  API_URL + "/media/" + skinData.category_tab_4_icon  : configurableData.category_tab_4_icon,
        category_tab_5_icon : skinData && skinData.category_tab_5_icon  ?  API_URL + "/media/" + skinData.category_tab_5_icon  : configurableData.category_tab_5_icon,
        category_tab_6_icon : skinData && skinData.category_tab_6_icon  ?  API_URL + "/media/" + skinData.category_tab_6_icon  : configurableData.category_tab_6_icon,
        category_tab_7_icon : skinData && skinData.category_tab_7_icon  ?  API_URL + "/media/" + skinData.category_tab_7_icon  : configurableData.category_tab_7_icon,
        category_tab_8_icon : skinData && skinData.category_tab_8_icon  ?  API_URL + "/media/" + skinData.category_tab_8_icon  : configurableData.category_tab_8_icon,
        category_tab_9_icon : skinData && skinData.category_tab_9_icon  ?  API_URL + "/media/" + skinData.category_tab_9_icon  : configurableData.category_tab_9_icon,
        category_tab_10_icon : skinData && skinData.category_tab_10_icon  ?  API_URL + "/media/" + skinData.category_tab_10_icon  : configurableData.category_tab_10_icon,
        language_icon: skinData && skinData.language_icon ? API_URL + "/media/" + skinData.language_icon : configurableData.language_icon,
        marquee_bg : skinData && skinData.marquee_bg  ?  API_URL + "/media/" + skinData.marquee_bg  : configurableData.marquee_bg,
        checkin_bg : skinData && skinData.checkin_bg  ?  API_URL + "/media/" + skinData.checkin_bg  : null,
        marquee_height : skinData && skinData.marquee_height  ? skinData.marquee_height  : configurableData.marquee_height ,
        marquee_text_color : skinData && skinData.marquee_text_color  ? skinData.marquee_text_color  : configurableData.marquee_text_color ,
        livechat: skinData && skinData.livechat ?  API_URL + "/media/" + skinData.livechat : configurableData.livechat,
        deposit_ewallet : skinData && skinData.deposit_ewallet  ? API_URL + "/media/" + skinData.deposit_ewallet  : configurableData.deposit_ewallet ,
        deposit_manual_deposit : skinData && skinData.deposit_manual_deposit  ? API_URL + "/media/" + skinData.deposit_manual_deposit  : configurableData.deposit_manual_deposit ,
        general_copy_link: skinData && skinData.general_copy_link ? API_URL + "/media/" + skinData.general_copy_link : configurableData.general_copy_link,
        general_downline: skinData && skinData.general_downline ? API_URL + "/media/" + skinData.general_downline : configurableData.general_downline,
        general_more_info: skinData && skinData.general_more_info ? API_URL + "/media/" + skinData.general_more_info : configurableData.general_more_info,
        general_share: skinData && skinData.general_share ? API_URL + "/media/" + skinData.general_share : configurableData.general_share,
        vip_ranking: skinData && skinData.vip_ranking ? API_URL + "/media/" + skinData.vip_ranking : configurableData.vip_ranking,
        logo: skinData && skinData.logo ? API_URL + "/media/" + skinData.logo : configurableData.logo,
        menu_home: skinData && skinData.menu_home ? API_URL + "/media/" + skinData.menu_home : configurableData.menu_home,
        menu_promo: skinData && skinData.menu_promo ? API_URL + "/media/" + skinData.menu_promo : configurableData.menu_promo,
        menu_deposit : skinData && skinData.menu_deposit  ? API_URL + "/media/" + skinData.menu_deposit  : configurableData.menu_deposit ,
        menu_history : skinData && skinData.menu_history  ? API_URL + "/media/" + skinData.menu_history  : configurableData.menu_history ,
        menu_contract_us : skinData && skinData.menu_contract_us  ? API_URL + "/media/" + skinData.menu_contract_us  : configurableData.menu_contract_us ,
        banner: skinData && skinData.banner ? API_URL + "/media/" + skinData.banner : configurableData.banner,
        banner2: skinData && skinData.banner2 ? API_URL + "/media/" + skinData.banner2 : null,
        deposit_easypay: skinData && skinData.deposit_easypay ? API_URL + "/media/" + skinData.deposit_easypay : configurableData.deposit_easypay,
        deposit_surepay : skinData && skinData.deposit_surepay  ? API_URL + "/media/" + skinData.deposit_surepay  : configurableData.deposit_surepay ,
        deposit_sun2pay : skinData && skinData.deposit_sun2pay  ? API_URL + "/media/" + skinData.deposit_sun2pay  : configurableData.deposit_sun2pay ,
        deposit_telcopay : skinData && skinData.deposit_telcopay  ? API_URL + "/media/" + skinData.deposit_telcopay  : configurableData.deposit_telcopay ,
        home_login_btn : skinData && skinData.home_login_btn  ? API_URL + "/media/" + skinData.home_login_btn  : configurableData.home_login_btn ,
        home_register_btn : skinData && skinData.home_register_btn  ? API_URL + "/media/" + skinData.home_register_btn  : configurableData.home_register_btn ,
        more_info_modal : skinData && skinData.more_info_modal  ? API_URL + "/media/" + skinData.more_info_modal  : configurableData.more_info_modal ,
        payment_banner : skinData && skinData.payment_banner  ? API_URL + "/media/" + skinData.payment_banner  : configurableData.payment_banner ,
        promo_moredetails : skinData && skinData.promo_moredetails  ? API_URL + "/media/" + skinData.promo_moredetails  : configurableData.promo_moredetails ,
        promo_joinnow : skinData && skinData.promo_joinnow  ? API_URL + "/media/" + skinData.promo_joinnow  : configurableData.promo_joinnow ,
        qrcode: skinData && skinData.qrcode ? API_URL + "/media/" + skinData.qrcode : configurableData.qrcode,
        telegram : skinData && skinData.telegram  ? API_URL + "/media/" + skinData.telegram  : configurableData.telegram ,
        whatsapp : skinData && skinData.whatsapp  ? API_URL + "/media/" + skinData.whatsapp  : configurableData.whatsapp ,
        wallet_deposit: skinData && skinData.wallet_deposit ? API_URL + "/media/" + skinData.wallet_deposit : configurableData.wallet_deposit,
        wallet_withdraw: skinData && skinData.wallet_withdraw ? API_URL + "/media/" + skinData.wallet_withdraw : configurableData.wallet_withdraw,
        wallet_profile: skinData && skinData.wallet_profile ? API_URL + "/media/" + skinData.wallet_profile : configurableData.wallet_profile,
        wallet_refresh: skinData && skinData.wallet_refresh ? API_URL + "/media/" + skinData.wallet_refresh : configurableData.wallet_refresh,
        withdraw_title: skinData && skinData.withdraw_title ? API_URL + "/media/" + skinData.withdraw_title : configurableData.withdraw_title,
        deposit_doitnow : skinData && skinData.deposit_doitnow  ? API_URL + "/media/" + skinData.deposit_doitnow  : configurableData.deposit_doitnow ,
        deposit_tng : skinData && skinData.deposit_tng  ? API_URL + "/media/" + skinData.deposit_tng  : configurableData.deposit_tng ,
        deposit_dgpay : skinData && skinData.deposit_dgpay  ? API_URL + "/media/" + skinData.deposit_dgpay  : configurableData.deposit_dgpay ,
        deposit_dgpay_ewallet : skinData && skinData.deposit_dgpay_ewallet  ? API_URL + "/media/" + skinData.deposit_dgpay_ewallet  : configurableData.deposit_dgpay_ewallet ,
        deposit_dana : skinData && skinData.deposit_dana  ? API_URL + "/media/" + skinData.deposit_dana  : configurableData.deposit_dana ,
        deposit_ovo : skinData && skinData.deposit_ovo  ? API_URL + "/media/" + skinData.deposit_ovo  : configurableData.deposit_ovo ,
        deposit_shopeepay : skinData && skinData.deposit_shopeepay  ? API_URL + "/media/" + skinData.deposit_shopeepay  : configurableData.deposit_shopeepay ,
        deposit_qris : skinData && skinData.deposit_qris  ? API_URL + "/media/" + skinData.deposit_qris  : configurableData.deposit_qris ,
        login_image : skinData && skinData.login_image  ? API_URL + "/media/" + skinData.login_image  : configurableData.login_image ,
        forget_password_image : skinData && skinData.forget_password_image  ? API_URL + "/media/" + skinData.forget_password_image  : configurableData.forget_password_image ,
        livechat_image : skinData && skinData.livechat_image  ? API_URL + "/media/" + skinData.livechat_image  : configurableData.livechat_image , 
        subline_icon : skinData && skinData.subline_icon  ? API_URL + "/media/" + skinData.subline_icon  : configurableData.subline_icon ,
        
        // For both image and color
        menusidebar_bg : skinData && skinData.menusidebar_bg  ? API_URL + "/media/" + skinData.menusidebar_bg  : configurableData.menusidebar_bg ,
        footer_bg : skinData && skinData.footer_bg  ? API_URL + "/media/" + skinData.footer_bg  : configurableData.footer_bg ,
        header_bg : skinData && skinData.header_bg  ? API_URL + "/media/" + skinData.header_bg  : configurableData.header_bg ,
        root_bg: skinData && skinData.root_bg ? API_URL + "/media/" + skinData.root_bg : configurableData.root_bg,
        menusidebar_bg_type : skinData && skinData.menusidebar_bg_type  ? skinData.menusidebar_bg_type  : configurableData.menusidebar_bg_type ,
        menusidebar_bg_color : skinData && skinData.menusidebar_bg_color  ? skinData.menusidebar_bg_color  : configurableData.menusidebar_bg_color ,
        footer_bg_type : skinData && skinData.footer_bg_type  ? skinData.footer_bg_type  : configurableData.footer_bg_type ,
        footer_bg_color : skinData && skinData.footer_bg_color  ? skinData.footer_bg_color  : configurableData.footer_bg_color ,
        header_bg_color : skinData && skinData.header_bg_color  ? skinData.header_bg_color  : configurableData.header_bg_color ,
        header_bg_type : skinData && skinData.header_bg_type  ? skinData.header_bg_type  : configurableData.header_bg_type ,
        root_bg_type: skinData && skinData.root_bg_type ? skinData.root_bg_type : configurableData.root_bg_type,
        root_bg_color: skinData && skinData.root_bg_color ? skinData.root_bg_color : configurableData.root_bg_color,
        wallet_welcome_bg_type: skinData && skinData.wallet_welcome_bg_type ? skinData.wallet_welcome_bg_type : configurableData.wallet_welcome_bg_type,
        wallet_welcome_bg: skinData && skinData.wallet_welcome_bg ? API_URL + "/media/" + skinData.wallet_welcome_bg : configurableData.wallet_welcome_bg,
        wallet_welcome_bg_color: skinData && skinData.wallet_welcome_bg_color ? skinData.wallet_welcome_bg_color : configurableData.wallet_welcome_bg_color,
        wallet_container_bg_type: skinData && skinData.wallet_container_bg_type ? skinData.wallet_container_bg_type : configurableData.wallet_container_bg_type,
        wallet_container_bg: skinData && skinData.wallet_container_bg ? API_URL + "/media/" + skinData.wallet_container_bg : configurableData.wallet_container_bg,
        wallet_container_bg_color: skinData && skinData.wallet_container_bg_color ? skinData.wallet_container_bg_color : configurableData.wallet_container_bg_color,
        vertical_tab: skinData && skinData.vertical_tab ? true :false,
        
        side_menu_icon_1 : skinData && skinData.side_menu_icon_1  ? API_URL + "/media/" + skinData.side_menu_icon_1  : null ,
        side_menu_icon_2 : skinData && skinData.side_menu_icon_2  ? API_URL + "/media/" + skinData.side_menu_icon_2  : null ,
        side_menu_icon_3 : skinData && skinData.side_menu_icon_3  ? API_URL + "/media/" + skinData.side_menu_icon_3  : null ,
        side_menu_icon_4 : skinData && skinData.side_menu_icon_4  ? API_URL + "/media/" + skinData.side_menu_icon_4  : null ,
        side_menu_icon_5 : skinData && skinData.side_menu_icon_5  ? API_URL + "/media/" + skinData.side_menu_icon_5  : null ,
        side_menu_icon_6 : skinData && skinData.side_menu_icon_6  ? API_URL + "/media/" + skinData.side_menu_icon_6  : null ,
        side_menu_icon_7 : skinData && skinData.side_menu_icon_7  ? API_URL + "/media/" + skinData.side_menu_icon_7  : null ,
        side_menu_icon_8 : skinData && skinData.side_menu_icon_8  ? API_URL + "/media/" + skinData.side_menu_icon_8  : null ,
        side_menu_icon_9 : skinData && skinData.side_menu_icon_9  ? API_URL + "/media/" + skinData.side_menu_icon_9  : null ,
        side_menu_icon_10 : skinData && skinData.side_menu_icon_10  ? API_URL + "/media/" + skinData.side_menu_icon_10  : null ,
        side_menu_icon_11 : skinData && skinData.side_menu_icon_11  ? API_URL + "/media/" + skinData.side_menu_icon_11  : null ,
        side_menu_icon_12 : skinData && skinData.side_menu_icon_12  ? API_URL + "/media/" + skinData.side_menu_icon_12 : null ,
        logout_icon : skinData && skinData.logout_icon  ? API_URL + "/media/" + skinData.logout_icon  : null ,
        login_icon : skinData && skinData.login_icon  ? API_URL + "/media/" + skinData.login_icon  : null ,

        // Side Menu
        enable_sidebar: skinData && skinData.enable_sidebar ? skinData.enable_sidebar : "",

        side_menu_route_1 : skinData && skinData.side_menu_route_1 && (skinData.side_menu_route_1 != "home")  ? skinData.side_menu_route_1  : "",
        side_menu_route_2 : skinData && skinData.side_menu_route_2 && (skinData.side_menu_route_2 != "home")  ? skinData.side_menu_route_2  : "",
        side_menu_route_3 : skinData && skinData.side_menu_route_3 && (skinData.side_menu_route_3 != "home")  ? skinData.side_menu_route_3  : "",
        side_menu_route_4 : skinData && skinData.side_menu_route_4 && (skinData.side_menu_route_4 != "home")  ? skinData.side_menu_route_4  : "",
        side_menu_route_5 : skinData && skinData.side_menu_route_5 && (skinData.side_menu_route_5 != "home")  ? skinData.side_menu_route_5  : "",
        side_menu_route_6 : skinData && skinData.side_menu_route_6 && (skinData.side_menu_route_6 != "home")  ? skinData.side_menu_route_6  : "",
        side_menu_route_7 : skinData && skinData.side_menu_route_7 && (skinData.side_menu_route_7 != "home")  ? skinData.side_menu_route_7  : "",
        side_menu_route_8 : skinData && skinData.side_menu_route_8 && (skinData.side_menu_route_8 != "home")  ? skinData.side_menu_route_8  : "",
        side_menu_route_9 : skinData && skinData.side_menu_route_9 && (skinData.side_menu_route_9 != "home")  ? skinData.side_menu_route_9  : "",
        side_menu_route_10 : skinData && skinData.side_menu_route_10 && (skinData.side_menu_route_10 != "home")  ? skinData.side_menu_route_10  : "",
        side_menu_route_11 : skinData && skinData.side_menu_route_11 && (skinData.side_menu_route_11 != "home")  ? skinData.side_menu_route_11  : "",
        side_menu_route_12 : skinData && skinData.side_menu_route_12 && (skinData.side_menu_route_12 != "home")  ? skinData.side_menu_route_12  : "",

        side_menu_text_1: skinData && skinData.side_menu_text_1 ? skinData.side_menu_text_1 : "",
        side_menu_text_2: skinData && skinData.side_menu_text_2 ? skinData.side_menu_text_2 : "",
        side_menu_text_3: skinData && skinData.side_menu_text_3 ? skinData.side_menu_text_3 : "",
        side_menu_text_4: skinData && skinData.side_menu_text_4 ? skinData.side_menu_text_4 : "",
        side_menu_text_5: skinData && skinData.side_menu_text_5 ? skinData.side_menu_text_5 : "",
        side_menu_text_6: skinData && skinData.side_menu_text_6 ? skinData.side_menu_text_6 : "",
        side_menu_text_7: skinData && skinData.side_menu_text_7 ? skinData.side_menu_text_7 : "",
        side_menu_text_8: skinData && skinData.side_menu_text_8 ? skinData.side_menu_text_8 : "",
        side_menu_text_9: skinData && skinData.side_menu_text_9 ? skinData.side_menu_text_9 : "",
        side_menu_text_10: skinData && skinData.side_menu_text_10 ? skinData.side_menu_text_10 : "",
        side_menu_text_11: skinData && skinData.side_menu_text_11 ? skinData.side_menu_text_11 : "",
        side_menu_text_12: skinData && skinData.side_menu_text_12 ? skinData.side_menu_text_12 : "",

        side_menu_active_1: skinData && skinData.side_menu_active_1 ? true : false,
        side_menu_active_2: skinData && skinData.side_menu_active_2 ? true : false,
        side_menu_active_3: skinData && skinData.side_menu_active_3 ? true : false,
        side_menu_active_4: skinData && skinData.side_menu_active_4 ? true : false,
        side_menu_active_5: skinData && skinData.side_menu_active_5 ? true : false,
        side_menu_active_6: skinData && skinData.side_menu_active_6 ? true : false,
        side_menu_active_7: skinData && skinData.side_menu_active_7 ? true : false,
        side_menu_active_8: skinData && skinData.side_menu_active_8 ? true : false,
        side_menu_active_9: skinData && skinData.side_menu_active_9 ? true : false,
        side_menu_active_10: skinData && skinData.side_menu_active_10 ? true : false,
        side_menu_active_11: skinData && skinData.side_menu_active_11 ? true : false,
        side_menu_active_12: skinData && skinData.side_menu_active_12 ? true : false,

        // Menu types
        menu_contact_us_text_type : skinData && skinData.menu_contact_us_text_type  ? skinData.menu_contact_us_text_type  : configurableData.menu_contact_us_text_type ,
        menu_contact_us_text_color : skinData && skinData.menu_contact_us_text_color  ? skinData.menu_contact_us_text_color  : null,
        menu_contact_us_default : skinData && skinData.menu_contact_us_default  ? skinData.menu_contact_us_default  : null,
        menu_contact_us_route : skinData && skinData.menu_contact_us_route && (skinData.menu_contact_us_route != "home")  ? skinData.menu_contact_us_route  : "/contactus",
        // menu_contact_us_text_color will be visible only when no data from api 
        static_menu_contact_us_text_color : configurableData.menu_contact_us_text_color,
        menu_contact_us_text : skinData && skinData.menu_contact_us_text  ? API_URL + "/media/" + skinData.menu_contact_us_text  : configurableData.menu_contact_us_text ,
        menu_deposit_text_type : skinData && skinData.menu_deposit_text_type  ? skinData.menu_deposit_text_type  : configurableData.menu_deposit_text_type ,
        menu_deposit_text_color : skinData && skinData.menu_deposit_text_color  ? skinData.menu_deposit_text_color  : null,
        menu_deposit_default : skinData && skinData.menu_deposit_default  ? skinData.menu_deposit_default  : null,
        menu_deposit_route : skinData && skinData.menu_deposit_route && (skinData.menu_deposit_route != "home")  ? skinData.menu_deposit_route  : "/deposit",
        // menu_deposit_text_color will be visible only when no data from api 
        static_menu_deposit_text_color : configurableData.menu_deposit_text_color,
        menu_deposit_text : skinData && skinData.menu_deposit_text  ? API_URL + "/media/" + skinData.menu_deposit_text  : configurableData.menu_deposit_text ,
        menu_history_text_type : skinData && skinData.menu_history_text_type  ? skinData.menu_history_text_type  : configurableData.menu_history_text_type ,
        menu_history_text_color : skinData && skinData.menu_history_text_color  ? skinData.menu_history_text_color  : null,
        menu_history_default : skinData && skinData.menu_history_default  ? skinData.menu_history_default  : null,
        menu_history_route : skinData && skinData.menu_history_route && (skinData.menu_history_route != "home")  ? skinData.menu_history_route  : "/history",
        // menu_history_text_color will be visible only when no data from api 
        static_menu_history_text_color : configurableData.menu_history_text_color,
        menu_history_text : skinData && skinData.menu_history_text  ? API_URL + "/media/" + skinData.menu_history_text  : configurableData.menu_history_text ,
        menu_promo_text_type  : skinData && skinData.menu_promo_text_type   ? skinData.menu_promo_text_type   : configurableData.menu_promo_text_type  ,
        menu_promo_text_color : skinData && skinData.menu_promo_text_color  ? skinData.menu_promo_text_color  : null,
        menu_promo_default : skinData && skinData.menu_promo_default  ? skinData.menu_promo_default  : null,
        menu_promo_route : skinData && skinData.menu_promo_route && (skinData.menu_promo_route != "home")  ? skinData.menu_promo_route  : "/promotions",
        // menu_promo_text_color will be visible only when no data from api 
        static_menu_promo_text_color : configurableData.menu_promo_text_color,
        menu_promo_text : skinData && skinData.menu_promo_text  ? API_URL + "/media/" + skinData.menu_promo_text  : configurableData.menu_promo_text ,
        menu_home_text_type  : skinData && skinData.menu_home_text_type   ? skinData.menu_home_text_type   : configurableData.menu_home_text_type  ,
        menu_home_text_color : skinData && skinData.menu_home_text_color  ? skinData.menu_home_text_color  : t(configurableData.menu_home_text_color) ,
        menu_home_default : skinData && skinData.menu_home_default  ? skinData.menu_home_default  : null ,
        menu_home_route : skinData && skinData.menu_home_route && (skinData.menu_home_route != "home")  ? skinData.menu_home_route  : "/" ,
        // menu_home_text_color will be visible only when no data from api 
        static_menu_home_text_color : configurableData.menu_home_text_color,
        menu_home_text : skinData && skinData.menu_home_text  ? API_URL + "/media/" + skinData.menu_home_text  : configurableData.menu_home_text ,
        // static_deposit_title will be visible only when no data from api 
        static_deposit_title : configurableData.deposit_title,
        deposit_title : skinData && skinData.deposit_title  ? skinData.deposit_title  :  null,
        
        profile_tab_icon_1 : skinData && skinData.profile_tab_icon_1  ? API_URL + "/media/" + skinData.profile_tab_icon_1  : null ,
        profile_tab_icon_2 : skinData && skinData.profile_tab_icon_2  ? API_URL + "/media/" + skinData.profile_tab_icon_2  : null ,
        profile_tab_icon_3 : skinData && skinData.profile_tab_icon_3  ? API_URL + "/media/" + skinData.profile_tab_icon_3  : null ,
        profile_tab_icon_4 : skinData && skinData.profile_tab_icon_4  ? API_URL + "/media/" + skinData.profile_tab_icon_4  : null ,
        profile_tab_icon_5 : skinData && skinData.profile_tab_icon_5  ? API_URL + "/media/" + skinData.profile_tab_icon_5  : null ,
        profile_tab_icon_6 : skinData && skinData.profile_tab_icon_6  ? API_URL + "/media/" + skinData.profile_tab_icon_6  : null ,
        profile_tab_icon_7 : skinData && skinData.profile_tab_icon_7  ? API_URL + "/media/" + skinData.profile_tab_icon_7  : null ,
        profile_tab_icon_8 : skinData && skinData.profile_tab_icon_8  ? API_URL + "/media/" + skinData.profile_tab_icon_8  : null ,
        profile_tab_icon_9 : skinData && skinData.profile_tab_icon_9  ? API_URL + "/media/" + skinData.profile_tab_icon_9  : null ,

        profile_tab_route_1 : skinData && skinData.profile_tab_route_1 && (skinData.profile_tab_route_1 != "home")  ? skinData.profile_tab_route_1  : "",
        profile_tab_route_2 : skinData && skinData.profile_tab_route_2 && (skinData.profile_tab_route_2 != "home")  ? skinData.profile_tab_route_2  : "",
        profile_tab_route_3 : skinData && skinData.profile_tab_route_3 && (skinData.profile_tab_route_3 != "home")  ? skinData.profile_tab_route_3  : "",
        profile_tab_route_4 : skinData && skinData.profile_tab_route_4 && (skinData.profile_tab_route_4 != "home")  ? skinData.profile_tab_route_4  : "",
        profile_tab_route_5 : skinData && skinData.profile_tab_route_5 && (skinData.profile_tab_route_5 != "home")  ? skinData.profile_tab_route_5  : "",
        profile_tab_route_6 : skinData && skinData.profile_tab_route_6 && (skinData.profile_tab_route_6 != "home")  ? skinData.profile_tab_route_6  : "",
        profile_tab_route_7 : skinData && skinData.profile_tab_route_7 && (skinData.profile_tab_route_7 != "home")  ? skinData.profile_tab_route_7  : "",
        profile_tab_route_8 : skinData && skinData.profile_tab_route_8 && (skinData.profile_tab_route_8 != "home")  ? skinData.profile_tab_route_8  : "",
        profile_tab_route_9 : skinData && skinData.profile_tab_route_9 && (skinData.profile_tab_route_9 != "home")  ? skinData.profile_tab_route_9  : "",

        profile_tab_text_1: skinData && skinData.profile_tab_text_1 ? skinData.profile_tab_text_1 : "",
        profile_tab_text_2: skinData && skinData.profile_tab_text_2 ? skinData.profile_tab_text_2 : "",
        profile_tab_text_3: skinData && skinData.profile_tab_text_3 ? skinData.profile_tab_text_3 : "",
        profile_tab_text_4: skinData && skinData.profile_tab_text_4 ? skinData.profile_tab_text_4 : "",
        profile_tab_text_5: skinData && skinData.profile_tab_text_5 ? skinData.profile_tab_text_5 : "",
        profile_tab_text_6: skinData && skinData.profile_tab_text_6 ? skinData.profile_tab_text_6 : "",
        profile_tab_text_7: skinData && skinData.profile_tab_text_7 ? skinData.profile_tab_text_7 : "",
        profile_tab_text_8: skinData && skinData.profile_tab_text_8 ? skinData.profile_tab_text_8 : "",
        profile_tab_text_9: skinData && skinData.profile_tab_text_9 ? skinData.profile_tab_text_9 : "",

        loading_image: skinData && skinData.loading_image ? API_URL + "/media/" + skinData.loading_image : configurableData.loading_image,
        // Jackpot
        jackpot_image : skinData && skinData.jackpot_image  ? API_URL + "/media/" + skinData.jackpot_image  : configurableData.jackpot_image , 
        jackpot_style: skinData && skinData.jackpot_style ? skinData.jackpot_style : "",
        jackpot_number: skinData && skinData.jackpot_number ? skinData.jackpot_number : "",
        jackpot_number_min_increment: skinData && skinData.jackpot_number_min_increment ? skinData.jackpot_number_min_increment : "",
        jackpot_number_max_increment: skinData && skinData.jackpot_number_max_increment ? skinData.jackpot_number_max_increment : "",
        // ClickToClaim
        enable_ctc: skinData && skinData.enable_ctc ? skinData.enable_ctc : configurableData.enable_ctc,
        ctc_bg : skinData && skinData.ctc_bg  ? API_URL + "/media/" + skinData.ctc_bg  : configurableData.ctc_bg,
        ctc_title_color: skinData && skinData.ctc_title_color ? skinData.ctc_title_color : configurableData.ctc_title_color,
        ctc_title_font_style: skinData && skinData.ctc_title_font_style ? skinData.ctc_title_font_style : configurableData.ctc_title_font_style,
        ctc_reward_color: skinData && skinData.ctc_reward_color ? skinData.ctc_reward_color : configurableData.ctc_reward_color,
        ctc_reward_font_style: skinData && skinData.ctc_reward_font_style ? skinData.ctc_reward_font_style : configurableData.ctc_reward_font_style,
        //Currency
        currency: (skinData && skinData.country) ? (
          skinData.country === 'SG' ? 'SGD' :
          skinData.country === 'MY' ? 'MYR' :
          skinData.country === 'ID' ? 'IDR' :
          skinData.country === 'TH' ? 'THB' :
          skinData.country === 'AUS' ? 'AUD' :
          '$'
        ) : ( '$' ),
        phone_format: (skinData && skinData.country) ? (
          skinData.country === 'SG' ? 'sg' :
          skinData.country === 'MY' ? 'my' :
          skinData.country === 'ID' ? 'id' :
          skinData.country === 'TH' ? 'th' :
          skinData.country === 'AUS' ? 'au' :
          'my'
        ) : ( 'my' ),
        
        game_launch_in_site: skinData && skinData.game_launch_in_site ? skinData.game_launch_in_site : false,
      }
      if (!skinData || skinData.skin_name == "undefined") {
        newRes = configurableData;
      }
      setConfigData(newRes)
    } catch (error) {
      console.log(error)
    }

  }
  useEffect(() => {
    getConfigData();
  }, [])

  return (
    <ConfigContext.Provider value={configData}>
      {props.children}
    </ConfigContext.Provider>
  )
}

