import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BalanceSection from "../components/BalanceSection";
import { ConfigContext } from "../ConfigContext";
import userService from "../service/user.service";
import { useTranslation } from "react-i18next";
import BalanceSectionNew from "./BalanceSectionNew";

const BalanceWrapperNew = ({
  currentUser,
  getWalletInfo,
  walletInfo,
  StopProduct,
}) => {
  const navigate = useNavigate();
  const configData = useContext(ConfigContext)
  const { t } = useTranslation();
  const SITE_NAME = process.env.REACT_APP_SITE_NAME;

  const [bank, setBank] = useState(null);

  const fetchBanks = async () => {
    try {
      // fetch customer bank details
      const customerBanks = await userService.getCustomBanks();
      const customerBank = customerBanks.data;
      if (customerBank.length === 0) {
        return;
      }

      let banks = [];
      customerBank.map((bank) =>
        banks.push({
          ...bank,
          metaData: {
            name: "",
          },
        })
      );

      const bank = customerBank[0];
      const bankData = await userService.getBankDetails(bank.bank);
      if (bankData.status === 200) {
        setBank({
          ...bank,
          metaData: bankData.data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchBanks();
  }, []);

  return (
    <div className="balance_wrapper">
      {/* Site Name for demo purpose, will have to remove after demo */}
      {(SITE_NAME !== "NNNN8888") ? (
        <div>
          <div className="login-container w-full" style={{borderRadius: "1rem", border: "2px solid #E6960C", boxShadow: "0 0 12px rgba(230, 150, 12, 0.8), 0 0 12px rgba(230, 150, 12, 0.8) inset"}}>
            <div className="left-column p-1" style={{flex: 1, borderRight: "2px solid #E6960C"}}>
              {
                !currentUser?
                  <div className="h-100 d-flex flex-column justify-content-center">
                    <div className="login-btn" onClick={() => navigate("/signin")}>
                      <img
                        src={configData ? configData.home_login_btn : ""}
                        className="login-image"
                        alt=""
                      />
                    </div>
                    <div className="register-btn" onClick={() => navigate("/signup")}>
                      <img
                        src={configData ? configData.home_register_btn : ""}
                        className="register-image"
                        alt=""
                      />
                    </div>
                  </div>
                  :
                  <>
                    <div className="center">
                      <div className="balance_game" style={{ color: configData ? configData.text_color : "" }}>{t("Cash")}:</div>
                      <div className="balance_credit" style={{ color: configData ? configData.text_color_secondary : "" }}>
                        {currentUser && walletInfo ? walletInfo.balance : "0.00"}
                      </div>
                    </div>
                    <div style={{ marginTop: "5px" }} className="center">
                      <div className="balance_game" style={{ color: configData ? configData.text_color : "" }}>{t("Chips")}:</div>
                      <div className="balance_credit" style={{ color: configData ? configData.text_color_secondary : "" }}>
                        {currentUser && walletInfo ? walletInfo.chips_balance : "0.00"}
                      </div>
                    </div>
                  </>
                
              }
            </div>
            <div className="right-column" style={{flex: 2}}>
              <div className="pbalance h-100">
                <BalanceSectionNew
                  getWalletInfo={getWalletInfo}
                  walletInfo={walletInfo}
                  StopProduct={StopProduct}
                  bank={bank}
                />
              </div>
            </div>
          </div>
        </div>
      ) : ( 
        /* For demo purpose, will have to remove after demo */
        currentUser && (
          <div>
            <div className="login-container w-full" style={{borderRadius: "1rem", border: "2px solid #E6960C", boxShadow: "0 0 12px rgba(230, 150, 12, 0.8), 0 0 12px rgba(230, 150, 12, 0.8) inset"}}>
              <div className="left-column p-1" style={{flex: 1, borderRight: "2px solid #E6960C"}}>
                  <div className="center">
                    <div className="balance_game" style={{ color: configData ? configData.text_color : "" }}>{t("Cash")}:</div>
                    <div className="balance_credit" style={{ color: configData ? configData.text_color_secondary : "" }}>
                      {currentUser && walletInfo ? walletInfo.balance : "0.00"}
                    </div>
                  </div>
                  <div style={{ marginTop: "5px" }} className="center">
                    <div className="balance_game" style={{ color: configData ? configData.text_color : "" }}>{t("Chips")}:</div>
                    <div className="balance_credit" style={{ color: configData ? configData.text_color_secondary : "" }}>
                      {currentUser && walletInfo ? walletInfo.chips_balance : "0.00"}
                    </div>
                  </div>
              </div>
              <div className="right-column" style={{flex: 2}}>
                <div className="pbalance h-100">
                  <BalanceSectionNew
                    getWalletInfo={getWalletInfo}
                    walletInfo={walletInfo}
                    StopProduct={StopProduct}
                    bank={bank}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default BalanceWrapperNew;
