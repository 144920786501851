function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const possibleDepositsDefault = [10, 30, 50, 100, 200, 250, 300, 500]
export const possibleDepositsAmount = (country) => {
  let amount;
  switch (country) {
    case 'id':
      amount = [100000, 300000, 500000, 1000000, 2000000, 2500000, 3000000, 5000000]
    break
    case 'th':
      amount = [1000, 3000, 5000, 10000, 20000, 25000, 30000, 5000]
    break
    default:
      amount = [10, 30, 50, 100, 200, 250, 300, 500]

  }
  return amount
}

export function generateMockData(numberOfRecords=20) {
  const prefixes = ['610', '611', '612', '614', '616', '617', '619'];
  const mockData = [];

  for (let i = 0; i < numberOfRecords; i++) {
    // Select a random prefix
    const prefix = prefixes[getRandomInt(0, prefixes.length - 1)];
    let record = prefix + getRandomInt(0, 9);

    // Generate the masked part
    const maskedLength = prefix === '011' ? 4 : 3;
    let masked = '';
    for (let j = 0; j < maskedLength; j++) {
        masked += '*';
    }
    record += masked;

    // Add two numbers at the end
    record += String(getRandomInt(10, 99));
    mockData.push(record);
  }

  return mockData;
}


export const mockedProductNames = ["918kiss","Pussy888","Jili","Mega888"]