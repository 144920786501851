import React, { useContext, useEffect, useState } from 'react'
import Marquee from 'react-fast-marquee'
import { ConfigContext } from '../../../ConfigContext';
import PhoneInput from 'react-phone-input-2';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { toast } from "react-toastify";
import userService from '../../../service/user.service';
import { Button } from 'antd';
import SignUp3 from '../../../pages/SignUp3';
import TransitionsModal from '../../common/TransitionsModal';
import { Icon } from "@iconify/react";
import authService from '../../../service/auth.service';
import tokenService from '../../../service/token.service';
import Loading from '../../Loading';
import useToast from '../../../utils/useToast';




const initialValues = {
    username: "",
    password: "",
    password2: "",
    cc: "60",
    mobile: "",
    tac: "",
    account_name: "",
    bank: 1,
  };

function LegacySignup3({ setUserId, setAuth, userId }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [values, setValues] = useState(initialValues);
    const [readOnly, setReadOnly] = useState(false);
    const [mobileValid, setMobileValid] = useState(false);
    const [usernameValid, setUsernameValid] = useState(false);
    const [passwordValid, setPasswordValid] = useState(false);
    const [password2Valid, setPassword2Valid] = useState(false);
    const [otpValid, setOtpValid] = useState(false);
  const [newsfeeds, setNewsfeeds] = useState([]);
  const [valid, setValid] = useState(false)

  const [text, setText] = useState("")
  const randomString = (length, chars) => {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}
let rString = randomString(6, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
const [captcha, setCaptcha] = useState(rString)

    const [otpLoading, setOtpLoading] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const [regDone, setRegDone] = useState(false);
    const [otpTime, setOtpTime] = useState();
  const [registered, setRegistered] = useState(false);
  const [error, setError] = useState('');

  const configData = useContext(ConfigContext);
  const { t } = useTranslation();
  const toast = useToast();

  const [validations, setValidations] = useState({
    username: "",
    password: "",
    password2: "",
    cc: "",
    mobile: "",
    non_field_errors: "",
    referrer_code: "",
    tac: "",
    account_name: "",
  });
  const getNewsfeedsList = async () => {
    try {
      const newslist = await userService.getNewsfeeds();

      setNewsfeeds(newslist && newslist.data ? newslist.data : []);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() =>{
    getNewsfeedsList();
    setCaptcha(rString);
  },[])
  

  const matchCaptcha = () => {
    if (text === captcha) {
        return true
    } else {
        setValid(true)
        return false
    }
}
const isInvalid = ()=>{
    const verifyCaptcha = matchCaptcha();
    if (!verifyCaptcha) {
        return true
    }
    let validationFailed = false
    // const currentValidations = {
    //     ...validations
    // };

    // ["username","password","password2","mobile","cc"].map(field=>{
    //     // console.log(field,updatedValues[field])
    //     if(!updatedValues[field]){
    //         currentValidations[field] = "This is required field"
    //         validationFailed= true
    //     }else{
    //         currentValidations[field] = ""
    //     }
    // })
    // if (updatedValues.password !== updatedValues.password2){
    //     currentValidations.password2 = "Password and Confirm Password Dont match" 
    //     validationFailed = true
    // } else {
    //     currentValidations.password2 = ""
    // }
    // if(validationFailed){
    //     setValidations({
    //         ...currentValidations
    //     })
    // }
    return validationFailed
}

  const handleChange = (e) => {
    if (e.target.name === "tac" && e.target.value.length < 5) {
      setValidations({
        ...validations,
        tac: "",
      });
    }
    if (e.target.name === "username" && e.target.value.length <= 5) {
      setUsernameValid(false)
    } else {
      setUsernameValid(true)
    }
    if (e.target.name === "tac" && e.target.value.length === 5) {
      verifyCode(e.target.value, userId);
      setOtpValid(true);
    }
    if (e.target.name === "tac" && e.target.value.length !== 5) {
      setOtpValid(false);
    }
    if (e.target.name === "password" && e.target.value.length >= 6) {
      setPasswordValid(true);
    }
    if (e.target.name === "password2" && e.target.value.length >= 6) {
      setPassword2Valid(true);
    }
    if (e.target.name === "tac" && e.target.value.length > 5) {
      return;
    }
    setValues((values) => {
      return { ...values, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let response;
    try {
      const updatedValues = {
        ...values,
        password2: values.password,
      };
      const validation = isInvalid(updatedValues)
      if(validation){
          return
      }
      response = await authService.getLegacyTac(updatedValues);
      if (
          response &&
          response.data &&
          response.data.id &&
          response.data.recipient
      ) {
          setUserId(response.data.id);
          tokenService.setRegisterUser({
              ...updatedValues,
              id: response.data.id,
          });
          toast.success("TAC sent successfully!");
      }
    } catch (err) {
      console.log("err => ", err, values);
      let username_message;
      let cc_message;
      let mobile_message;
      let password_message;
      let password2_message;
      let referrer_code_message;
      let non_field_errors_message;
      let bank_message;
      let account_name_message
      let account_number_message


      if (err.response && err.response.data && err.response.data.username) {
        username_message = err.response.data.username[0]
        setError(username_message)
        return
      }
      if (err.response && err.response.data && err.response.data.cc) {
        cc_message = err.response.data.cc;
        setError(cc_message)
        return
      }
      if (err.response && err.response.data && err.response.data.mobile) {
        mobile_message = err.response.data.mobile;
        setError("Mobile : " + mobile_message)
        return
      }
      if (err.response && err.response.data && err.response.data.password) {
        password_message = err.response.data.password;
        setError("Password : " + password_message)
        return
      }
      if (err.response && err.response.data && err.response.data.password2) {
        password2_message = err.response.data.password2;
        setError("Conform Password : " + password2_message)
        return
      }
      if (err.response && err.response.data && err.response.data.bank) {
        bank_message = err.response.data.bank;
        setError("Bank : " + bank_message)
        return
    }
    if (err.response && err.response.data && err.response.data.account_name) {
        account_name_message = err.response.data.account_name;
        setError("Bank Name : " + account_name_message)
        return
    }
    if (err.response && err.response.data && err.response.data.account_number) {
        account_number_message = err.response.data.account_number;
        setError("Account Number : " + account_number_message)
        return
    }
      if (
        err.response &&
        err.response.data &&
        err.response.data.referrer_code
      ) {
        referrer_code_message = err.response.data.referrer_code;
        setError("Referrer Code : " + referrer_code_message)
        return
      }
      if (
        err.response &&
        err.response.data &&
        err.response.data.non_field_errors
      ) {
        non_field_errors_message = err.response.data.non_field_errors[0];
        toast.error(non_field_errors_message);
      }
      setValidations({
        ...validations,
        username: username_message,
        password: password_message,
        password2: password2_message,
        mobile: mobile_message,
        bank: bank_message,
        account_name: account_name_message,
        account_number: account_number_message,
        cc: cc_message,
        referrer_code: referrer_code_message,
        non_field_errors: non_field_errors_message,
      });
    }
    setOtpLoading(false);
  };

  const verifyCode = async (code, userId) => {
    try {
      await authService.verifyLegacyTac({ userId, code });
      setRegDone(true);
      // navigate('/signup3');
    } catch (err) {
      console.log(err);
      let tac_message;
      setRegDone(false);
      if (err.response && err.response.data && err.response.data.tac) {
        const username_msg = err.response.data.tac;
        if (username_msg.length > 0) {
          tac_message = username_msg[0];
        }
        setError(tac_message)
        return
        
      }
      if (err.response && err.response.data && err.response.data.detail) {
        const username_msg = err.response.data.detail;
        tac_message = username_msg;
        setError(tac_message)
        return
      }
      setValidations({
        ...validations,
        tac: tac_message,
      });
    }
  };

  const getReferralCode = () => {
    const searchParams = new URLSearchParams(location.search);
    const myParam = searchParams.get("referrer");
    const localStorageCode = localStorage.getItem("referralCode");
    if (myParam || localStorageCode) {
      setValues((values) => {
        return { ...values, referrer_code: myParam || localStorageCode };
      });
      localStorage.removeItem("referralCode");
      sessionStorage.setItem("from_refer", myParam || localStorageCode);
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("from_refer")) {
      const ref_code = sessionStorage.getItem("from_refer");
      localStorage.setItem("referralCode", ref_code);
      sessionStorage.removeItem("from_refer");
      navigate(`/signup/?referrer=${ref_code}&openExternalBrowser=1`);
    }
    getReferralCode();
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("registerUser");
    if (user) {
      const parsedUser = JSON.parse(user);
      if (parsedUser.id) {
        if (
          String(parsedUser.mobile).length >= 8 &&
          String(parsedUser.mobile).length <= 11
        ) {
          setMobileValid(true);
        }
        setValues({
          ...values,
          mobile: parsedUser.mobile,
          cc: parsedUser.cc,
          username: parsedUser.username
        });
        setUserId(parsedUser.id);
      }
    }
  }, [localStorage.getItem("registerUser")]);


    return (
<>
<div className='legacy3' >
  {toast.ToastContainer}
            <div className="noticebar"
                style={{
                    backgroundImage: `url(${configData ? configData.marquee_bg : ""
                        })`,
                }}
            >
                <Marquee style={{ height: `${configData ? configData.marquee_height : 10}px` }}>
                    {newsfeeds.map((feed, newsindex) => (
                        <span
                            key={newsindex}
                            style={{ color: configData ? configData.marquee_text_color : "" }}
                        >
                            {feed.text}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    ))}
                </Marquee>
            </div>
            {
                !registered ?
                    <>
                        <form className='p-3 pb-1'>
                            <table className='w-100'>
                                <tbody>
                                    <tr>
                                        <td style={{color : configData?configData.text_color :""}}>{t("Bank")} {t("Account")}<br></br>{t("Name")}</td>
                                        <td style={{color : configData?configData.text_color :""}}>:</td>
                                        <td>
                                            <input style={{ backgroundColor: configData ? configData.inputs_bg : "white" }}
                                                type="text"
                                                name="account_name"
                                                className="form-control"
                                                id="form1a"
                                                placeholder={t("Full_Name")}
                                                onChange={handleChange} />
                                            {/* {
                                                validations &&
                                                <span class="standard-required-message">{validations.username}</span>
                                            } */}
                                            <p style={{ textTransform: "capitalize" }}>* Must be the same as your bank account <br /> name.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{color : configData?configData.text_color :""}}>{t("Mobile_No")}. /<br></br>{t("Username")}</td>
                                        <td style={{color : configData?configData.text_color :""}}>:</td>
                                        <td>

                                            <PhoneInput
                                                inputStyle={{
                                                    width: "100%",
                                                    borderRadius: "4px",
                                                    border: "none",
                                                    backgroundColor: configData ? configData.inputs_bg : ""
                                                }}
                                                containerStyle={{ marginTop: "0px", marginBottom: "10px", borderRadius: "4px", backgroundColor: configData ? configData.inputs_bg : "" }}
                                                buttonStyle={{ borderRadius: "4px", border: "none", backgroundColor: configData ? configData.inputs_bg : "" }}
                                                country={configData ? (configData.phone_format) : "my"}
                                                onlyCountries={configData ? [(configData.phone_format)] : ["my"]}
                                                onChange={(phone, country) => {
                                                    if (
                                                        String(phone).length >= 8 &&
                                                        String(phone).length <= 11
                                                    ) {
                                                        setMobileValid(true);
                                                    }
                                                    const reducedPhone = phone.replace(country.dialCode, "");
                                                    setValues({
                                                        ...values,
                                                        cc: country.dialCode,
                                                        mobile: reducedPhone,
                                                        username: country.dialCode + reducedPhone
                                                    });
                                                }}
                                                className="has-borders text-white"
                                            />
                                            {/* {
                                                validations &&
                                                <span class="standard-required-message">{validations.mobile}</span>
                                            } */}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td style={{color : configData?configData.text_color :""}}>{t("Password")}</td>
                                        <td style={{color : configData?configData.text_color :""}}>:</td>
                                        <td>
                                            <input style={{ backgroundColor: configData ? configData.inputs_bg : "" }}
                                             type="password" 
                                             name="password" 
                                             className="form-control" 
                                             placeholder={t("Password")} 
                                             id="password_input" 
                                             onChange={handleChange} />
                                            <i className="glyphicon glyphicon-eye-close" id="password_input_trigger"></i>
                                            {/* {
                                                validations &&
                                                <span class="standard-required-message">{validations.password}</span>
                                            } */}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td style={{color : configData?configData.text_color :""}}>{t("Confirm")} {t("Password")}</td>
                                        <td style={{color : configData?configData.text_color :""}}>:</td>
                                        <td>
                                            <input style={{ backgroundColor: configData ? configData.inputs_bg : "" }} 
                                            type="password" 
                                            name="password2" 
                                            className="form-control" 
                                            placeholder={t("Confirm") + " " + t("Password")} 
                                            id="password_input2" 
                                            onChange={handleChange} />
                                            <i className="glyphicon glyphicon-eye-close" id="password_input_trigger"></i>
                                            {/* {
                                                validations &&
                                                <span class="standard-required-message">{validations.password2}</span>
                                            } */}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td style={{color : configData?configData.text_color :""}}> {t("Referral_Code")}</td>
                                        <td style={{color : configData?configData.text_color :""}}>:</td>
                                        <td>
                                            <input style={{ backgroundColor: configData ? configData.inputs_bg : "" }}
                                             type="text" 
                                             name="referrer-code" 
                                             className="form-control" 
                                             placeholder={t("Referral_Code") + " (" + t("optional") + ")"} 
                                             value={values.referrer_code} 
                                             readOnly={readOnly} 
                                             id="referrer-code" 
                                             onChange={handleChange} />
                                            <i className="glyphicon glyphicon-eye-close" id="password_input_trigger"></i>
                                            {/* {
                                                validations &&
                                                <span class="standard-required-message">{validations.referrer_code}</span>
                                            } */}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td className='pt-1'>
                                            <div className="captcha-container">
                                                <span className="captcha_text me-0"  style={{color: configData ? configData.text_color : "" ,}}>{captcha}</span>
                                            </div>
                                        </td>
                                        <td style={{color : configData?configData.text_color :""}}>:</td>
                                        <td>
                                            <input style={{ backgroundColor: configData ? configData.inputs_bg : "" }}
                                                type="text"
                                                name="validation-code"
                                                className="form-control"
                                                placeholder={t("Validation_Code")}
                                                onChange={(e) => setText(e.target.value)}
                                                id="password_input"
                                                maxLength="6" />
                                            <div>{valid && <span class="standard-required-message">Wrong Captcha !!!</span>}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="verification-code" style={{ color: configData ? configData.marquee_text_color : "" }}>
                                <p style={{color: configData ? configData.text_color : "" ,}}>Press GET CODE button to receive Verification Code via SMS.</p>
                                <div className='d-flex justify-content-between align-items-center mt-2' >
                                    <div className="otp-input-box d-flex">
                                        <input
                                            type="text"
                                            name="tac"
                                            className={`form-control text-white`}
                                            id="form1b"
                                            placeholder={t("SMS_OTP")}
                                            onChange={handleChange}
                                            value={values.tac}
                                        />
                                    </div>
                                    <Button
                                        loading={otpLoading}
                                        data-back-button
                                        className="text-center btn_green register_btn get-code"
                                        style={{
                                            backgroundColor: configData
                                                ? configData.secondary_color
                                                : "",
                                            color: configData ? configData.text_color : "" ,
                                            borderColor: configData ? configData.border_color : "",
                                            height: "40px",
                                        }}
                                        onClick={handleSubmit}
                                    >
                                        {t("GET_OTP")}
                                    </Button>
                                </div>
                            </div>
                            <Button
                            disabled={!regDone}
                                data-back-button
                                className="text-center mx-auto w-100 btn-full btn_green register_btn mt-4"
                                style={{
                                    backgroundColor: configData
                                        ? configData.secondary_color
                                        : "",
                                    color: configData ? configData.text_color : "",
                                    height: "40px",
                                    marginBottom: "1rem",
                                }}
                                // onClick={() => navigate("/signup3")}
                                onClick={() => setRegistered(true)}
                            >
                                {t("REGISTER")}
                            </Button>
                        </form>
                        <p className="input-title text-center my-1 font-15">
                            {t("Have_An_Account")}
                        </p>
                        <p
                            className="input-title text-center my-1 font-15"
                            style={{ color: configData ? configData.text_color : "" }}
                            onClick={(e) => {
                                e.preventDefault();
                                if (values && values.referrer_code) {
                                    localStorage.setItem("from_refer", true);
                                }
                                navigate("/signin");
                            }}
                        >
                            {t("Login_Here")}
                        </p>
                    </>
                    :
                    <SignUp3 userId={userId} setAuth={setAuth} account_name_read={JSON.parse(localStorage.getItem("registerUser")).account_name} />
            }

          <TransitionsModal
          open={error ? true : false}
          onClose={() => setError('')}
          style={{ textAlign: "center", padding: "1rem", border: "none" }}
        >
          <div>
            <div className="flex flex-row justify-center">
              <Icon icon="codicon:error" color="#f27474" width={80} />
              {/* <img
                src={selectedGame.image_mobile}
                alt={selectedGame.name}
                style={{ width: "150px", borderRadius: "1rem" }}
              /> */}
            </div>
            <p className="mt-3"
            style={{color: configData ? configData.text_color : "#999", fontWeight:"100", fontSize:"1.125emP"}}>
              {error}
            </p>
            <button
              onClick={() => setError('')}
              className={`btn btn-sm bg-blue-dark rounded-xs text-uppercase
            font-700 w-100 btn-l mt-3 h-12`}
            style={{fontSize:"1.0625em"}}
            >
              {t("OK")}
            </button>
          </div>
        </TransitionsModal>
        </div>
        {isLoading && <Loading />}
</>
    )
}

export default LegacySignup3