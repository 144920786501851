import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../service/auth.service";
import UserService from "../../../service/user.service";
import Loading from "../../Loading";
import { ConfigContext } from "../../../ConfigContext";
import { useTranslation } from "react-i18next";
import "../layout.css"

export default function BalanceSection3({
  getWalletInfo,
  walletInfo,
  StopProduct,
  bank,
}) {
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState(null);
  const [startedGames, setStartedGames] = useState([]);
  // const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [turnOver, setTurnOver] = useState(null)
  const configData = useContext(ConfigContext)


  const getNotificationInfo = async () => {
    // const getNotification = await UserService.getNotificationCount();
    // setCount(getNotification.data.count);
  };

  useEffect(() => {
    const fetchWalletInfo = async () => {
      const user = AuthService.getCurrentUser();
      if (user) {
        await getWalletInfo();
        setCurrentUser(user);
        getNotificationInfo();
      }
    };
    fetchWalletInfo();
  }, [localStorage.getItem("user")]);
  const getStartedGames = async (userId) => {
    try {
      const startedProducts = await UserService.getStartedGames(userId);
      if (startedProducts.data) {
        setStartedGames(startedProducts.data);
      }
    } catch (error) {
      setStartedGames([]);
    }
  };

  const getTurnover = async () => {
    try {
      const turnover = await UserService.getTurnover();
      if (turnover.data && turnover.data.length) {
        setTurnOver(turnover.data[0])
      }
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    if (walletInfo && walletInfo.user && walletInfo.user.id) {
      getStartedGames(walletInfo.user.id);
    }
    getTurnover()
  }, [walletInfo]);

  // const checkBg = (val) => {
  //   if (val && (val[0] === "#" || val.includes("rgb") || val.includes("hsl"))) {
  //     return val
  //   }
  //   return `url(${val})`

  //   // if(val.includes("http") || val.includes(".png") || val.includes(".jpeg") || val.includes("jpg") || val.includes("svg")){
  //   //   return `url(${val})`
  //   // }
  //   // return val;
  // }
  const checkBg = (configData, type) => {
    // console.log("val", val)
    if (!configData) {
      return {}
    }
    if (configData[configData[type]][0] === "#" || configData[configData[type]].includes("rgb") || configData[configData[type]].includes("hsl")) {
      return {backgroundColor: configData[configData[type]]}
    }
    return {backgroundImage: `url(${configData[configData[type]]})`}
  }

  return (
    <div style={{ borderRadius: "1rem", ...checkBg(configData, "wallet_container_bg_type") }} className="balance-container w-full d-flex flex-column">
      <div className="balance-context w-full">
        <div className="right-column h-full d-flex w-full">
          <div className="balance_btns d-flex w-full align-items-stretch" style={{marginLeft: "auto"}}>
            <div className="balance-img_container cursor-pointer" role="button" style={{borderRight: `2px solid ${configData?configData.border_color_secondary:""}`, paddingRight: "10px", flex: 1}}>
              <img
                style={{width: "20px"}}
                src={configData ? configData.wallet_deposit : ""}
                alt="Deposit"
                onClick={(e) => {
                  navigate("/deposit");

                }}
              />
              <p className="text-center text-uppercase" style={{fontSize: "10px", color: configData ? configData.text_color : "" }}>{t("Deposit")}</p>
            </div>
            <div className="balance-img_container cursor-pointer" role="button" style={{borderRight: `2px solid ${configData?configData.border_color_secondary:""}`, paddingRight: "10px", flex: 1}}>
              <img
                style={{width: "20px"}}
                src={configData ? configData.wallet_withdraw : ""}
                alt="Withdraw"
                onClick={(e) => {
                  navigate("/withdraw");
                }}
              />
              <p className="text-center text-uppercase" style={{fontSize: "10px", color: configData ? configData.text_color : "" }}>{t("Withdraw")}</p>
            </div>
            <div className="balance-img_container cursor-pointer" role="button" style={{borderRight: `2px solid ${configData?configData.border_color_secondary:""}`, paddingRight: "10px", flex: 1}}>
              <img
                style={{width: "20px"}}
                src={configData ? configData.wallet_profile : ""}
                alt="Profile"
                onClick={(e) => {
                  navigate("/profile");
                }}
              />
              <p className="text-center text-uppercase" style={{fontSize: "10px", color: configData ? configData.text_color : "" }}>{t("Profile")}</p>
            </div>
            <div className="balance-img_container cursor-pointer d-flex flex-column justify-content-center" role="button" style={{borderRight: `2px solid ${configData?configData.border_color_secondary:""}`, paddingRight: "10px", flex: 1, lineHeight: 1.2}}>
              <p className="text-center" style={{color: configData ? configData.text_color : "" }}>{currentUser && walletInfo?walletInfo.user.username:""}</p>
              <p className="text-center" style={{color: configData ? configData.text_color_secondary : "" }}>{currentUser && walletInfo?walletInfo.balance:""}</p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="d-flex mt-auto justify-content-between">
        <div className="min-deposit text-center">
          {t("Min")} {t("Deposit")}: &nbsp; {turnOver && turnOver.min_deposit ? turnOver.min_deposit.toFixed(2) : 0}
        </div>
        <div className="min-withdrawl text-center">
          {t("Min")} {t("Withdrawal")}: &nbsp; {turnOver && turnOver.min_turnover ? turnOver.min_turnover.toFixed(2) : 0}
        </div>
      </div> */}
      {isLoading && <Loading />}
    </div>
  );
}
