import "./App.css";
import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-loading-skeleton/dist/skeleton.css";
import { ToastContainer } from "react-toastify";
import AuthService from "./service/auth.service";
import reactManifest from "react-manifest";

import "antd/dist/reset.css";
import MainLayout from "./MainLayout";
import AuthLayout from "./AuthLayout";
import { ConfigContext } from "./ConfigContext";
import userService from "./service/user.service";
import { loginSupportBoard } from "./utils/support_board/login";
import NoInternet from "./NoInternet";
import TransitionsModal from "./components/common/TransitionsModal";
import { LanguageContext } from "./i18n/LanguageContext";
import { countries } from "./i18n/languages";
import { languages } from "./i18n";
import ReactGA from 'react-ga4';

export const UserContext = React.createContext();
const CONTACTUS_OPTION = process.env.REACT_APP_CONTACTUS_OPTION;
const SITE_NAME = process.env.REACT_APP_SITE_NAME;
const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;

function App() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token_id = searchParams.get("token_id");
  const { language, setLanguage } = useContext(LanguageContext)
  if(GOOGLE_ANALYTICS){
    ReactGA.initialize(GOOGLE_ANALYTICS);
    ReactGA.send({ hitType: "pageview" })
  }
  const [isAuth, setAuth] = useState(
    AuthService.getCurrentUser() ? true : false
  );
  const [mode, setMode] = useState(localStorage.getItem("PayApp-Theme"));
  const [isOnline, setIsOnline] = useState(true);

  const configData = useContext(ConfigContext);

  function getFaviconEl() {
    return document.getElementById("favicon");
  }

  useEffect(() => {
    window.addEventListener("storage", () => {
      setMode(localStorage.getItem("PayApp-Theme"));
    });
  }, []);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (token_id === null && user) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, [localStorage.getItem("user"), token_id]);

  useEffect(() => {
    Array.from(
      document.querySelectorAll(".ant-tabs-tab-active .ant-tabs-tab-btn")
    ).forEach((ele) => {
      ele.style.background = configData ? configData.secondary_color : "";
      ele.style.color = configData ? configData.text_color : "";
    });
    Array.from(document.querySelectorAll(".Mui-selected")).forEach((ele) => {
      ele.style.background = configData ? configData.secondary_color : "";
      ele.style.color = configData ? configData.text_color : "";
    });
    // document.title = configData ? configData.title : "";

    if (configData) {
      const favicon = getFaviconEl(); // Accessing favicon element
      // favicon.href = configData.favicon;
    }
    // document.title = configData ? configData.title : "";
    const handleFocus = () => {
      // document.title = configData ? configData.title : "";
    };
    window.addEventListener("focus", handleFocus);
    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [location.pathname, configData]);

  useEffect(() => {
    const countryCondition = (configData && configData.country)?(configData.country.toLowerCase()):"my"
    const [country] = countries.filter(country=>country.country.toLowerCase() == countryCondition)
    const default_lang = configData && configData.default_lang?configData.default_lang:country.default_lang
    const default_country = (configData && configData.country)?configData.country.toLowerCase():country.country.toLowerCase()
    const default_flag = country.flag
    let [default_label]=languages.filter(lang=>lang.key==default_lang)
    setLanguage({
      lang: default_lang,
      img: default_flag,
      label: default_label?default_label.label:"Select Language",
      country: default_country
    })
  }, [configData])
  
  // const checkBg = (val) => {
  //   console.log("val", val);
  //   if (val && (val[0] === "#" || val.includes("rgb") || val.includes("hsl"))) {
  //     return val;
  //   }
  //   return `url(${val})`;

  //   // if(val.includes("http") || val.includes(".png") || val.includes(".jpeg") || val.includes("jpg") || val.includes("svg")){
  //   //   return `url(${val})`
  //   // }
  //   // return val;
  // };
  const checkBg = (configData, type) => {
    if (!configData) {
      return {}
    }
    if (configData[configData[type]][0] === "#" || configData[configData[type]].includes("rgb") || configData[configData[type]].includes("hsl")) {
      return {backgroundColor: configData[configData[type]]}
    }
    return {backgroundImage: `url(${configData[configData[type]]})`}
  }
  useEffect(() => {
    if (isAuth && CONTACTUS_OPTION === "supportboard") {
      const loginSB = async () => {
        const getWallet = await userService.getBalance();
        setTimeout(() => {
          loginSupportBoard(getWallet.data.username);
        }, 500);
      };
      loginSB();
    }
  }, [isAuth]);

  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    // Cleanup event listeners
    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  useEffect(() => {
    if (isOnline) {
      setIsOnline(navigator.onLine);
    }
  }, [isOnline]);

  // Define a function to handle tab visibility change
  const handleTabVisibilityChange = () => {
    let logoFileName = `favicons/${SITE_NAME}.png`;
    let siteName = SITE_NAME;
    if (siteName === "ECI88V2") {
      siteName = "ECI88";
    }
    if (document.hidden) {
      // Your code to handle the window resize event goes here
      changeTabInfo(siteName, logoFileName);
    } else {
      setTimeout(() => {
        changeTabInfo(siteName, logoFileName);
      }, 500)
    }
  };

  // Attach the event listener when the component mounts
  useEffect(() => {
    document.addEventListener("visibilitychange", handleTabVisibilityChange);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener(
        "visibilitychange",
        handleTabVisibilityChange
      );
    };
  }, []); // The empty dependency array ensures that the effect runs only once when the component mounts

  useEffect(() => {
    let logoFileName = `favicons/${SITE_NAME}.png`;
    let siteName = SITE_NAME;
    if (siteName === "ECI88V2") {
      siteName = "ECI88";
    }
    changeTabInfo(siteName, logoFileName);
  }, [SITE_NAME]);

  function changeTabInfo(title, faviconUrl) {
    document.title = title;

    const link = document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = faviconUrl;
    } else {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.href = faviconUrl;
      document.head.appendChild(newLink);
    }
  }

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        ...checkBg(configData, "root_bg_type")
      }}
    >
      <UserContext.Provider value={{ mode }}>
        <ToastContainer />
        <Routes>
          {isOnline ? (
            <Route
              path="/*"
              element={
                isAuth ? (
                  <MainLayout setAuth={setAuth} />
                ) : (
                  <AuthLayout setAuth={setAuth} />
                )
              }
            />
          ) : (
            <Route path="/*" element={<NoInternet />} />
          )}
        </Routes>
        {CONTACTUS_OPTION === "supportboard" ? (
          <div
            id="live-chat"
            style={{
              display: "none",
              position: "fixed",
              top: "54px",
              bottom: "60px",
              width: "100%",
              maxWidth: "500px",
              backgroundSize: "cover",
              backgroundImage: `url(${
                configData ? configData.livechat_image : ""
              })`,
            }}
          ></div>
        ) : null}
      </UserContext.Provider>
    </div>
  );
}

export default App;
