import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';
import authService from '../../../service/auth.service';
import userService from '../../../service/user.service';
import Footer from '../../../components/Footer';
import MenuNotification from '../../../components/MenuNotification';
import MenuSidebar from '../../../components/MenuSidebar';
import Loading from '../../../components/Loading';
import TransitionsModal from "../../common/TransitionsModal";
import { countries, languages } from "../../../i18n/languages";
import { LanguageContext } from "../../../i18n/LanguageContext";
import { ConfigContext } from "../../../ConfigContext";
import { Typography } from "@mui/material";

const Profile3 = (
    { walletInfo,
        setAuth }
) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const { t } = useTranslation();
    const configData = useContext(ConfigContext)
    const [cuBanks, setCuBanks] = useState([]);
    const [banks, setBanks] = useState([]);
    const [languageModal, setLanguageModal] = useState(false);
    const { language, setLanguage } = useContext(LanguageContext)
    const handleLanguageClick = () => {
        setLanguageModal(true);
    };

    const getCustomBanklist = async () => {
        const custombanks = await userService.getCustomBanks();
        let custombanklist = [];
        custombanks.data.forEach((game) => {
            if (game) {
                custombanklist.push({
                    bank: game.bank,
                    value: game.id,
                    label: game.name,
                    icon: game.icon,
                    number: game.number,
                    name: game.name,
                });
            }
        });
        setCuBanks(custombanklist);
    };
    const getBankList = async () => {
        const getBanks = await userService.getBanks();
        setBanks(getBanks.data);
    }
    useEffect(() => {
        getBankList();
        getCustomBanklist();
    },[])



    return (
        <div className="t1">
            <div>
                <div className="page">
                    <div className="profile3">
                        <div className="t1">{t("Profile")}</div>
                        <div className="user-info">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>{t("Username")}</td>
                                        <td>: </td>
                                        <td>
                                            <var>
                                                {walletInfo && walletInfo.username
                                                ? walletInfo.username
                                                : t("No_Account")} &nbsp;✅
                                            </var>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("Mobile_No")}</td>
                                        <td>: </td>
                                        <td>
                                            <var>{walletInfo && walletInfo.mobile
                                                ? walletInfo.mobile
                                                : t("No_items_found")} 
                                            </var>
                                        </td>
                                    </tr>
                                    {
                                    cuBanks.map((cuBank, index) => {
                                        const newBank = banks.filter(bank => bank.id == cuBank.bank)
                                        let bankName = "";
                                        if (newBank && newBank.length) {
                                            bankName = newBank[0].name
                                        }
                                        return (
                                            <>
                                            <tr>
                                                <td>{t("Bank")}</td>
                                                <td>: </td>
                                                <td><var>{bankName}</var></td>
                                            </tr>
                                             <tr>
                                                <td>{t("Bank_Accounts")}</td>
                                                <td>: </td>
                                                <td><var>{cuBank.name}</var></td>
                                            </tr>
                                            <tr>
                                                <td>{t("Bank_Details")}</td>
                                                <td>: </td>
                                                <td><var>{cuBank.number}</var></td>
                                            </tr>
                                         </>
                                        )
                                    })}
                                </tbody>
                            </table>
                            </div>
                            <div className="t1">{t("Settings")}</div>
                            <div className="settings-info">
                                <a className="t2" href="/change-password">{t("Change_Password")}</a>
                                <p className="t2" onClick={handleLanguageClick}>{t("Change_Language")}</p>
                                <form method="post" action="/Login/Logout">
                                    <a className="t2" onClick={(e) => {
                                        e.preventDefault();
                                        authService.logout();
                                        sessionStorage.removeItem("popup_isonce_onsite")
                                        navigate("/signin");
                                        setAuth(false)
                                    }}>
                                        {t("Log_out")}
                                    </a>
                                </form>
                                
                                {/* <a className="t2" href="#logout">Logout</a> */}
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <MenuNotification />
                    <MenuSidebar />
                </div>
            {isLoading && <Loading />}
            <TransitionsModal
                title={"Select Language"}
                className="antd-promotion-modal"
                footer={[]}
                open={languageModal}
                onClose={() => { setLanguageModal(false)}}
                style={{ textAlign: "center", height: "100%" }}
                containerStyle={{ height: "100%" }}
            >
                <div>
                {
                    countries.map(country => {
                    const all_languages = configData && configData.available_languages?country.supported_languages.filter(supported_language => configData.available_languages.split(",").includes(supported_language.lang)):country.supported_languages;
                    return (
                    <Typography key={country} className={`${all_languages.length?"d-flex":"d-none"} align-items-center mb-2`} onClick={() => {
                        // setLanguage({
                        //   lang: language.key,
                        //   img: language.img
                        // })
                    }} sx={{ py: 1, px: 1, fontSize: 20, borderBottom: "1px solid #ccc" }}>
                        <img style={{ width: "30px", marginRight: "10px" }} src={country.flag} alt="" />

                        {
                        all_languages.map((supported_language, index) => {
                            return <div key={supported_language.lang}>
                                    <div role="button" onClick={() => {
                                    setLanguage({
                                        lang: supported_language.lang,
                                        img: country.flag,
                                        country: country.country
                                    })
                                    setLanguageModal(false)
                                    }} 
                                    style={{ 
                                    color: language && (language.country == country.country) && (language.lang === supported_language.lang) ? 
                                            (configData ? configData.text_color_secondary : "") : 
                                            configData ? configData.text_color : "" }}>{supported_language.label}
                                            {index<(all_languages.length-1) ? " | ": ""}
                                    </div>
                                </div>
                        })
                        }
                    </Typography>
                    )})
                }
                </div>
            </TransitionsModal>
        </div>
        
    )
}

export default Profile3