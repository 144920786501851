import * as React from "react";
import "../component.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";

const _style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "transparent",
  boxShadow: 24,
  p: 4,
};

export default function TransitionsModal({
  children,
  open,
  onClose,
  style,
  title,
  containerStyle
}) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={{..._style, ...containerStyle}}>
          <div style={{ maxHeight: "80vh", overflowY: "auto", borderRadius: "15px", paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "1rem", paddingBottom: "1rem"}} className="ant-modal-content">
            {title && <h4 style={{ paddingBottom: "1rem", textAlign: "center" }}>{title}</h4>}
            {
              onClose ? 
            <div
              onClick={onClose}
              style={{ position: "absolute", right: "12px", top: "12px" }}
            >
              <CloseIcon />
            </div> : null
            }
            {children}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
