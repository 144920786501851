import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfigContext } from "../../../ConfigContext";
import userService from "../../../service/user.service";
import { useTranslation } from "react-i18next";
import BalanceSection3 from "./BalanceSection3";
import Loading from "../../Loading";
import "../layout.css"
const Auth3 = ({
  currentUser,
  getWalletInfo,
  walletInfo,
  StopProduct,
}) => {
  const navigate = useNavigate();
  const configData = useContext(ConfigContext)
  const { t } = useTranslation();

  const [bank, setBank] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [startedGames, setStartedGames] = useState([]);

  const getStartedGames = async (userId) => {
    try {
      const startedProducts = await userService.getStartedGames(userId);
      if (startedProducts.data) {
        setStartedGames(startedProducts.data);
      }
    } catch (error) {
      setStartedGames([]);
    }
  };
  useEffect(() => {
    if (walletInfo && walletInfo.user && walletInfo.user.id) {
      getStartedGames(walletInfo.user.id);
    }
  }, [walletInfo]);
  const fetchBanks = async () => {
    try {
      // fetch customer bank details
      const customerBanks = await userService.getCustomBanks();
      const customerBank = customerBanks.data;
      if (customerBank.length === 0) {
        return;
      }

      let banks = [];
      customerBank.map((bank) =>
        banks.push({
          ...bank,
          metaData: {
            name: "",
          },
        })
      );

      const bank = customerBank[0];
      const bankData = await userService.getBankDetails(bank.bank);
      if (bankData.status === 200) {
        setBank({
          ...bank,
          metaData: bankData.data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchBanks();
  }, []);

  return (
    <div className="balance_wrapper" style={{backgroundColor: "rgb(45, 45, 45)", position: "relative", zIndex: "10"}}>
      <div>
      {!currentUser && (
        <div>
          <div className="login-container w-full">
            <div className="left-column">
              <div className="login-btn" onClick={() => navigate("/signin")}>
                <img
                  src={configData?configData.home_login_btn :""}
                  className="login-image"
                  alt=""
                />
              </div>
            </div>
            <div className="right-column">
              <div className="register-btn" onClick={() => navigate("/signup")}>
                <img
                  src={configData?configData.home_register_btn :""}
                  className="register-image"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {
        currentUser &&
        <div className="login-container w-full" style={{ borderRadius: "1rem", }}>
          <div className="left-column p-1" style={{ flex: 6 }}>
            <div className="pbalance h-100">
              <BalanceSection3
                getWalletInfo={getWalletInfo}
                walletInfo={walletInfo}
                StopProduct={StopProduct}
                bank={bank}
              />
            </div>

          </div>
          <div className="right-column d-flex justify-content-end align-items-center" style={{ flex: 1 }}>
                <div className="balance-img_container cursor-pointer pe-3" role="button">
                  <img
                    style={{ width: "40px" }}
                    src={configData ? configData.wallet_refresh : ""}
                    alt="Refresh"
                    onClick={async (e) => {
                      setLoading(true)
                      startedGames.forEach(startedGame => {
                        StopProduct(startedGame.id)
                      })
                      setTimeout(() => {
                        setLoading(false)
                      }, 500);
                    }}
                  />
                </div>
          </div>
        </div>
      }
      </div>
      {isLoading && <Loading />}

    </div>
  );
};

export default Auth3;
