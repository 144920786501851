import React, { useState, useEffect, useContext } from "react";
import userService from "../service/user.service";
import Loading from "./Loading";
import { ConfigContext } from "../ConfigContext";
import TransitionsModal from "./common/TransitionsModal";
import TransitionsModalPromotion from "./common/TransitionsModalPromotion";
import PromotionDescription, { formatPromotionMessage } from "./PromotionDescription"
import { useTranslation } from "react-i18next";
import InfoIcon from '@mui/icons-material/Info';
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

const amountlist = [
    { id: 2, value: "30" },
    { id: 3, value: "50" },
    { id: 4, value: "100" },
    { id: 5, value: "200" },
    { id: 6, value: "250" },
    { id: 7, value: "300" },
    { id: 8, value: "500" },
    { id: 9, value: "1000" },
    { id: 10, value: "3000" },
    { id: 11, value: "5000" },
    { id: 12, value: "10000" },
    { id: 13, value: "15000" },
];

const initialValues = {
    ttype: "EW",
    amount: '',
    merchant_bank_account: 0,
};

const EWalletTransfer = ({ ewalletid, ewalletsList, getMerchantEWalletslist, vendor }) => {
    const dgPayValues = {
        ttype: "EW",
        amount: '',
        pg: "dgpay"
    };
    const [values, setValues] = useState(((ewalletid == "dgpay") || (vendor == "dgpay"))?dgPayValues:initialValues);
    
    // const [radioValue, setRadioValue] = useState(1);
    const [modalOpen, setModalOpen] = useState(false);
    const [promotionList, setPromotionList] = useState([]);
    // const [selectedTnC, setSelectedTnC] = useState({
    //     label: '',
    //     value: 0
    // });
    const [selectedTnC, setSelectedTnC] = useState({
        title: "",
        description: "",
    });
    const [isLoading, setLoading] = useState(false);
  const configData = useContext(ConfigContext)
  const {t} = useTranslation();
  const [depSuccessful, setDepSuccessful] = useState('');

  const navigate = useNavigate()


    const [validations, setValidations] = useState({
        amount: "",
        non_field_errors: "",
        minAmt: ""
    })

    const {
        amount: amountVal,
        non_field_errors: nonVal,
        minAmt: minAmtVal,
    } = validations

    const onChangeRadio = (e) => {
        // setRadioValue(e.target.value);
        if (!e.target.checked) {
          setValues((preValues) => {
            const newVals = JSON.parse(JSON.stringify(preValues))
            delete newVals["promotion"]
            return { ...newVals };
          });
        } else {
          setValues((values) => {
            return { ...values, promotion: e.target.value };
          });
        }
      };

    const handleNumChange = (e) => {
        const RE = /^\d*\.?\d{0,18}$/;
        if (RE.test(e.target.value) || e.target.value === '') {
            setValues(values => {
                return { ...values, [e.target.name]: e.target.value };
            });
        }
    }

    const handleDeposit = async e => {
        e.preventDefault();

        if (values.amount < 30) {
            let min_amt_msg;
            min_amt_msg = "min deposit amount is 30.";
            setValidations({
                ...validations, minAmt: min_amt_msg
            });
            return;
        }

        setLoading(true);

        let response;
        try {
            response = await userService.createDeposit(values);
            if (!response.data.action) {
                setDepSuccessful(response.data.id)
                return;
            }
            const url = (vendor == "dgpay")?response.data.action:response.data.action.url;
            const bankdata = response.data.action.data;

            let form_data = new FormData();

            if ((vendor !== "dgpay")) {
                Object.entries(bankdata).forEach(([key, value]) => {
                    form_data.append(`${key}`, value);
                })
            }

            try {

                var form = document.createElement("form");

                form.method = "POST";
                form.action = url;

                let element = [{}];
                if ((vendor !== "dgpay")) {
                    Object.entries(bankdata).forEach(([key, value], index) => {
                        element[index] = document.createElement("input");
                        element[index].name = key;
                        element[index].value = value;
                        form.appendChild(element[index]);
                    })
                }

                document.body.appendChild(form);
                form.style = "display: none";

                form.submit();

                document.body.removeChild(form);

            } catch (error) {
                console.log("error ==> ", error);
            }

        } catch (err) {
            console.log("err => ", err, values)
            let amount_msg;
            let non_msg;
            
            if (err.response.data.amount) {
                amount_msg = err.response.data.amount;
            }
            if (err.response.data.non_field_errors) {
                non_msg = err.response.data.non_field_errors;
            }
            setValidations({
                ...validations, amount: amount_msg, non_field_errors: non_msg
            });
        }
        setLoading(false);
    }

    const getPromotions = async () => {
        setLoading(true);
        const promotionListing = await userService.getPromotionsDepositList();

        // const nonObj = {
        //     id: 0,
        //     title: "No promotion",
        //     active: true
        // }

        // promotionListing.data.push(nonObj);
        promotionListing.data.sort((a, b) => a.id - b.id);

        setPromotionList(promotionListing.data.filter(v=>v.other.applicable));
        // setSelectedTnC(promotionListing.data[0]);
        // setRadioValue(promotionListing.data[0].id);
        setLoading(false);
    }

    useEffect(() => {
        getMerchantEWalletslist();
        getPromotions();
    }, [])

    useEffect(() => {
        if (ewalletid && ewalletsList && (ewalletid !=="dgpay") && (vendor !== "dgpay")) {
            setValues(values => {
                return { ...values, merchant_bank_account: ewalletid };
            });
        }
        if (vendor =="dgpay") {
            setValues(values => {
                return { ...values, provider: ewalletid };
            });
        }
    }, [ewalletid, ewalletsList, vendor])

    return (
        <div className="">
            <div className="d-flex justify-center">
                <img src={ewalletsList && ewalletsList.find((ewallet) => ewallet.id === Number(ewalletid)) && ewalletsList.find((ewallet) => ewallet.id === Number(ewalletid)).icon} alt="" className="icon icon-lg" />
            </div>
            <div className="form-custom form-label my-4">
                <input
                    name="amount"
                    type="text"
                    className="form-control rounded-xs"
                    id="c3"
                    placeholder={t("Amount")}
                    onChange={handleNumChange}
                    value={values.amount}
                />
                <div className={`${amountVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{amountVal}</div>
                <div className={`${minAmtVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{minAmtVal}</div>
            </div>

            {
                ewalletid !== "dgpay" && <div className="d-flex text-center justify-content-between tab-scroll-wrap flex-wrap" style={{ gap: "8px" }}>
                {amountlist.map((item) => (
                    <div className="pr-10 zIndex0 mb-2" key={item.id} style={{ width: "calc(25% - 6px)" }}>
                        <div
                            className={`icon icon-m shadow-bg shadow-bg-s rounded-xs ${values.amount === item.value
                                ? "border color-highlight"
                                : "bg-highlight"
                                }`}
                            style={{ width: "100%" }}
                            onClick={() => setValues({ ...values, amount: item.value })}
                        >
                            {item.value}
                        </div>
                    </div>
                ))}
            </div>}

            {/* <Radio.Group onChange={onChangeRadio} value={radioValue} className="my-4">
                <Space direction="vertical">
                    {(promotionList && promotionList.length > 0) && promotionList.filter(item => item.active).map(item => {
                        return (
                            <Radio
                                value={item.id}
                                key={item.id}
                                onChange={() => setSelectedTnC(item)}
                                onClick={() => {
                                    setModalOpen(true);
                                    if (item.id !== 0) {
                                        setValues(values => {
                                            return { ...values, promotions_applied: item.id }
                                        })
                                    } else {
                                        delete values.promotions_applied;
                                    }
                                }}
                            >
                                {item.title}
                            </Radio>
                        )
                    })}
                </Space>
            </Radio.Group> */}

            {/* {
                ewalletid == "dgpay" &&
                <div className="form-group deposit-form-group">
                            <label style={{color: "#fff"}} for="Provider">{t("Provider")}</label>
                <select
                    style={{color: "black"}}
                    className="form-control rounded-xs"
                    value={values.provider} placeholder="Select Provider" onChange={(e)=>setValues({...values,provider:e.target.value})} id="Provider" name="Provider">
                    <option value="">{t("Select_A_Value")}</option>
                    {
                        [{id:10,name:"Dana"},{id:11,name:"OVO"},{id:12,name:"ShopeePay"},{id:30,name:"Qris"}].map((payment, idx) => (
                            <option value={payment.id}>
                                {payment.name}
                            </option>
                        ))
                    }
                </select>
            </div>
            } */}
            {configData&&!configData.enable_ctc && 
            <>
            <h4 className="mt-4" style={{color: configData?configData.text_color :""}}>{t("Bonus")} {t("Selection")} <span style={{color: configData?configData.text_color_secondary:""}}>({t("optional")}):</span></h4>
            <div className="px-2 border border-white">
                {promotionList &&
                    promotionList.length > 0 &&
                    promotionList
                        .filter((item) => item.active)
                        .map((item) => {
                            return (
                                <div className="bonus-radio_container d-flex align-items-center my-2">
                                    <div className="bonus-selector d-flex position-relative" style={{ overflow: "hidden" }}>
                                        <input className="bonus-radio" name="bonus-radio" type="checkbox" id="" value={item.id}
                                            onChange={e=>{
                                                // if(item.id === 0){
                                                //   return
                                                // }
                                                if (e.target.checked) {
                                                  setModalOpen(true);
                                                  setSelectedTnC(item)
                                                } else {
                                                  setSelectedTnC({
                                                    title: "",
                                                    description: "",
                                                  })
                                                }
                                                onChangeRadio(e)
                                              }}
                                              checked={item.id == values.promotion}
                                            onClick={() => {
                                                // setModalOpen(true);
                                                if (item.id !== 0) {
                                                    setValues((values) => {
                                                        return { ...values, promotion: item.id };
                                                    })
                                                } else {
                                                    delete values.promotion;
                                                }

                                            }}
                                        />
                                        <div className="bonus-select p-2 d-flex justify-content-center align-items-center">
                                            <span className="bonus-check">&#10004;</span>
                                        </div>
                                    </div>
                                    <label className="ml-1 text-uppercase font-bold" style={{ color: configData?configData.text_color :"" }} htmlFor="">{item.title}</label>
                                </div>
                            );
                        })}
            </div>
            <p className="text-md d-flex mt-2 align-items-center" style={{color: configData?configData.text_color_secondary:"", gap: "5px", lineHeight: 1.5}}><InfoIcon />{promotionList && promotionList.length ? t("PromotionMessage") : t("PromotionMessage2")}</p>
            
            {/* <div className={`${nonVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{nonVal}</div> */}
            <div>
                {
                nonVal && nonVal.map((item, index) => (
                <div key={index}>
                    {formatPromotionMessage(item)}
                </div>
                ))
                }
            </div>
            </>
            }
            <button
                className="btn btn-full bg-blue-dark rounded-xs text-uppercase font-700 w-100 btn-s mt-3 h-40"
                onClick={handleDeposit}
            >
                {t("Deposit")}
            </button>
            <TransitionsModal
        title={''}
        centered
        open={depSuccessful ? true : false}
        footer={[]}
        // onClose={() => setDepSuccessful(false)}
      >
        <div className="d-flex align-items-center">
        <Icon icon="ep:success-filled" color="#52c41a" fontSize={22} />
        <p style={{fontSize:"14px", paddingLeft:"10px"}}>{t("Deposit_is_successful")}. <br />
          {t("Transaction_Id")}: {depSuccessful}</p>
        </div>

        <button style={{marginLeft:"auto", background: "#1677ff", fontWeight:"100", fontSize:"15px"}}
                                onClick={() => {
                                  setDepSuccessful('')
                                  navigate("/history")

                                }}
                                className={`btn btn-sm d-flex py-2 ml-auto rounded-xs text-uppercase
                                            mt-3`}>{t("OK")}
                            </button>
      </TransitionsModal>
            <TransitionsModalPromotion
                title={selectedTnC.title}
                className="antd-promotion-modal"
                footer={[]}
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <PromotionDescription
                    field={selectedTnC.description}
                    data={""}
                />
            </TransitionsModalPromotion>
            {/* <TransitionsModal
                title={selectedTnC.title}
                centered
                open={modalOpen}
                footer={[]}
                onClose={() => setModalOpen(false)}
            >
                <p className="text-lg font-semibold">{t("Terms&Conditions")}</p>

                <div
                className="mt-2 promo_modal"
                dangerouslySetInnerHTML={{ __html: selectedTnC.description }}
                />
            </TransitionsModal> */}
            {
                isLoading && <Loading />
            }
        </div>
    );
};

export default EWalletTransfer;
