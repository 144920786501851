import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import tokenService from '../../../service/token.service';
import authService from '../../../service/auth.service';
import { ConfigContext } from '../../../ConfigContext';
import Loading from '../../Loading';
import Marquee from 'react-fast-marquee';
import userService from '../../../service/user.service';
import TransitionsModal from '../../common/TransitionsModal';
import { Icon } from '@iconify/react';
import PhoneInput from 'react-phone-input-2';

const initialValues = {
    username: "",
    password: "",
};

function SignIn3({ setAuth }) {
    const [values, setValues] = useState(initialValues);
    const [newsfeeds, setNewsfeeds] = useState([]);
    const configData = useContext(ConfigContext);
    const [mobileValid, setMobileValid] = useState(false);
    const { t } = useTranslation();

    const [validations, setValidations] = useState({
        username: "",
        password: "",
        refresh: "",
    });
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token_id = searchParams.get("token_id");
    const signupVersion = configData && configData.signup_version ? configData.signup_version : process.env.REACT_APP_SIGNUP_VERSION;

    useEffect(() => {
        if (token_id) {
            tokenService.removeUser();
            handleStealthLogin(token_id);
        }
    }, [token_id]);

    const handleStealthLogin = async (tokenId) => {
        setLoading(true);
        try {
            const { data } = await authService.stealthLogin(tokenId);
            if (data && data.access) {
                setTimeout(() => {
                    tokenService.setUser(data);
                    setLoading(false);
                    navigate("/");
                    setAuth(true);
                }, 500);
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    };
    const getNewsfeedsList = async () => {
        setLoading(true);
        try {
            const newslist = await userService.getNewsfeeds();

            setNewsfeeds(newslist && newslist.data ? newslist.data : []);
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    };

    // const {
    //     username: nameVal,
    //     password: passVal,
    //     refresh: refreshVal,
    // } = validations;

    useEffect(() => {
        getNewsfeedsList();
    }, []);


    const handleChange = (e) => {
        setValues((values) => {
            return { ...values, [e.target.name]: e.target.value };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await authService.login(values);
            setTimeout(() => {
                setLoading(false);
            }, 500);
            navigate("/");
            setAuth(true);
        } catch (err) {
            console.log("err ==> ", err);
            let username_msg;
            let password_message;
            let refresh_msg;
            if (err.response && err.response.data && err.response.data.username) {
                username_msg = err.response.data.username;
                setError(username_msg)
                setLoading(false);
                return
            }
            if (err.response && err.response.data && err.response.data.refresh) {
                refresh_msg = err.response.data.refresh;
                setError(refresh_msg)
                setLoading(false);
                return
            }
            if (err.response && err.response.data && err.response.data.detail) {
                refresh_msg = err.response.data.detail;
                setError(refresh_msg)
                setLoading(false);
                return
            }
            if (err.response && err.response.data && err.response.data.password) {
                password_message = err.response.data.password;
                setError(password_message)
                setLoading(false);
                return
            }
            setValidations({
                ...validations,
                username: username_msg,
                password: password_message,
                refresh: refresh_msg,
            });
            setLoading(false);
        }
    };
    return (
        <div className=''>
            <div
                className="noticebar"
                style={{
                    // height: "30px",
                    backgroundImage: `url(${configData ? configData.marquee_bg : ""
                        })`,
                }}
            >
                <Marquee style={{ height: `${configData ? configData.marquee_height : 10}px` }}>
                    {newsfeeds.map((feed, newsindex) => (
                        <span
                            key={newsindex}
                            style={{ color: configData ? configData.marquee_text_color : "" }}
                        >
                            {feed.text}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    ))}
                </Marquee>
            </div>
            <div className="login-panel standard-form-container px-0 pt-0" >

                <div className="d-flex align-items-center justify-content-center mb-3">
                    <img src={configData ? configData.login_image : ""} alt="" style={{ width: "100%", height: "auto", maxHeight: "250px" }} />
                </div>

                <div className="px-4">
                    <div>
                        <h1
                            className="font-23 font-700 mb-3"
                            style={{
                                color: configData ? configData.text_color : "",
                            }}
                        >
                            {t("LOGIN")}
                        </h1>
                    </div>
                    <p className="input-title my-2 font-15">
                        {t("Mobile_No")}
                    </p>
                    <div>
                        <div
                            className={`input-style has-borders has-icon validate-field mb-2  ${values.username ? "input-style-active" : ""
                                }`}
                        >
                                <PhoneInput
                                    inputStyle={{
                                        width: "100%",
                                        borderRadius: "4px",
                                        border: "none",
                                        backgroundColor: configData ? configData.inputs_bg : ""
                                    }}
                                    containerStyle={{ marginTop: "0px", marginBottom: "10px", borderRadius: "4px", backgroundColor: configData ? configData.inputs_bg : "" }}
                                    buttonStyle={{ borderRadius: "4px", border: "none", backgroundColor: configData ? configData.inputs_bg : "" }}
                                    country={configData ? (configData.phone_format) : "my"}
                                    onlyCountries={configData ? [(configData.phone_format)] : ["my"]}
                                    onChange={(phone, country) => {
                                        if (
                                            String(phone).length >= 8 &&
                                            String(phone).length <= 11
                                        ) {
                                            setMobileValid(true);
                                        }
                                        const reducedPhone = phone.replace(country.dialCode, "");
                                        setValues({
                                            ...values,
                                            cc: country.dialCode,
                                            mobile: reducedPhone,
                                            username: country.dialCode + reducedPhone
                                        });
                                    }}
                                    className="has-borders text-white"
                                />
                            <i className="fa fa-times disabled invalid color-red-dark"></i>
                            <i className="fa fa-check disabled valid color-green-dark"></i>
                            {/* <em>(required)</em> */}
                            {/* <div
                                className={`${nameVal === "" ? "valid-feedback" : "invalid-feedback"
                                    } `}
                            >
                                {nameVal}
                            </div>
                            <div
                                className={`${refreshVal === "" ? "valid-feedback" : "invalid-feedback"
                                    } `}
                            >
                                {refreshVal}
                            </div> */}
                        </div>
                        <p className="input-title my-2 font-15" >
                            {t("Password")}
                        </p>
                        <div
                            className={`input-style has-borders has-icon validate-field mb-4  ${values.password ? "input-style-active" : ""
                                }`}
                        >
                            <input
                                name="password"
                                type="password"
                                className={`form-control validate-password text-white font-15`}
                                id="form1a"
                                placeholder="Password"
                                onChange={handleChange}
                                style={{ padding: "10px 12px", borderRadius: "4px", border: "none" }}
                            />
                            <i className="fa fa-times disabled invalid color-red-dark"></i>
                            <i className="fa fa-check disabled valid color-green-dark"></i>
                            {/* <em>(required)</em> */}
                            {/* <div
                                className={`${passVal === "" ? "valid-feedback" : "invalid-feedback"
                                    } `}
                            >
                                {passVal}
                            </div> */}
                        </div>
                        <div
                            data-back-button
                            className="text-center btn-full btn_green mt-4 font-15 font-100"
                            style={{
                                backgroundColor: configData
                                    ? configData.secondary_color
                                    : "",
                                color: configData ? configData.text_color : "",
                                border: `1px solid ${configData ? configData.border_color : ""}`,
                            }}
                            onClick={handleSubmit}
                        >
                            {t("LOGIN")}
                        </div>
                        <p className="input-title text-center font-15" style={{ marginTop: "20px" }} >
                            {t("Do_Not_Have")}
                        </p>

                        <div
                            data-back-button
                            className="text-center btn-full btn_green register_btn font-15 font-100"
                            style={{
                                backgroundColor: configData
                                    ? configData.secondary_color
                                    : "",
                                color: configData ? configData.text_color : "",
                                border: `1px solid ${configData ? configData.border_color : ""}`,
                                margin: "20px auto 5px"
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                if (localStorage.getItem("from_refer")) {
                                    localStorage.removeItem("from_refer");
                                    navigate(-1);
                                } else {
                                    navigate("/signup");
                                }
                            }}
                        >
                            {t("REGISTER")}
                        </div>
                        
                        <div
                            data-back-button
                            className="text-center btn-full btn_green register_btn font-15 font-100"
                            style={{
                                backgroundColor: configData
                                    ? configData.secondary_color
                                    : "",
                                color: configData ? configData.text_color : "",
                                border: `1px solid ${configData ? configData.border_color : ""}`,
                                margin: "20px auto 5px"
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                signupVersion && signupVersion === "V3" ? (
                                    navigate('/contactus')
                                ) : navigate("/forget-password");
                            }}
                        >
                            {t("Forget_Password")}
                        </div>
                    </div>
                    
                </div>
                {isLoading && <Loading />}
                <TransitionsModal
                        open={error ? true : false}
                        onClose={() => setError('')}
                        style={{ textAlign: "center", padding: "1rem", border: "none" }}
                    >
                        <div>
                            <div className="flex flex-row justify-center">
                                <Icon icon="codicon:error" color="#f27474" width={80} />
                           </div>
                            <p className="mt-3"
                                style={{ color: configData ? configData.text_color : "#999", fontWeight: "100", fontSize: "1.125emP" }}>
                                {error}
                            </p>
                            <button
                                onClick={() => setError('')}
                                className={`btn btn-sm bg-blue-dark rounded-xs text-uppercase
                                            font-700 w-100 btn-l mt-3 h-12`}
                                style={{ fontSize: "1.0625em" }}
                            >
                                {t("OK")}
                            </button>
                        </div>
                    </TransitionsModal>
            </div>
        </div>
    )
}

export default SignIn3